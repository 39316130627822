import CONSTANTS from '../CONSTANTS';

const initialState = {
  patients: [],
};

const usersPatientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.USERS_PATIENTS_SET_LIST:
        return { ...state, patients: action.patients };
    default:
      return state;
  }
};

export default usersPatientsReducer;
