import React, { Suspense } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import PropTypes from "prop-types";
import LoadingLocal from "plugins/TS-lib-utils-public/components/Loading/LoadingLocal";
const RoleForm = React.lazy(() => import("./RoleForm"));
const ModuleForm = React.lazy(() => import("./ModuleForm"));
const ActionForm = React.lazy(() => import("./ActionForm"));

const ModalActions = React.memo((props) => {
  const { modal, toggle, className, type, title, moduleId, item } = props;
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className={className} centered>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          {type === "action" && (
            <Suspense fallback={<LoadingLocal />}>
              <ActionForm moduleId={moduleId} action={item} toggle={toggle} />
            </Suspense>
          )}
          {type === "module" && (
            <Suspense fallback={<LoadingLocal />}>
              <ModuleForm module={item} toggle={toggle} />
            </Suspense>
          )}
          {type === "role" && (
            <Suspense fallback={<LoadingLocal />}>
              <RoleForm role={item} toggle={toggle} />
            </Suspense>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
});

ModalActions.propTypes = {
  modal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(["action", "module", "role"]),
};

export default ModalActions;
