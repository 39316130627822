import CONSTANTS from '../CONSTANTS';

export const actionGetAnalysisList = (page = 0, limit = 10 , search=null) => ({
    type: CONSTANTS.ANALYSIS_GET_LIST,
    page,
    limit,
    search
});

export const actionSetAnalysisList = (analysis) => ({
  type: CONSTANTS.ANALYSIS_SET_LIST,
  analysis,
});