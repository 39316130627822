import AxiosService from "plugins/TS-lib-utils-public/services/AxiosService"; 

class AnomaliesService extends AxiosService {
    constructor() {
        super('management');
    }
  
    getAnomaliesList = async (page, limit, search = null) => {
        let query;
        if (search) {
            query = `/anomalies/list/?page=${page}&limit=${limit}&search=${search}`;
        } else {
            query = `/anomalies/list/?page=${page}&limit=${limit}`;
        }
        const response = await this.get(
            query,
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            }
        );
        return response;
    };

    editAnomaly = async (anomalyId, state, comment) => {
        const response = await this.put(
            `/anomalies/${anomalyId}`,
            {
                state,
                comment
            },
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            }
        );
        return response;
    };

    resendStudy = async (anomalyId, hl7Id) => {
        const response = await this.put(
            `/anomalies/${anomalyId}/resend`,
            {
                hl7_id: hl7Id
            },
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            }
        );
        return response;
    };

    getAnomaly = async (anomalyId) => {
        const response = await this.get(
            `/anomalies/${anomalyId}`,
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            }
        );
        return response;
    };

}
export default new AnomaliesService();
