import React from 'react';
import PropTypes from "prop-types";
import { Table } from 'reactstrap';
import { translate } from "react-translate";
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';

const TablePractices = (props) => {

  const { practices, loading } = props;
  const { t } = props;


  return (
    <>
      {loading ?
        (<LoadingLocal />)
        :
        (<div className="table-data">
          <Table>
            <thead>
              <tr>
                <th> {t("name-practice")} </th>
                <th> {t("street-practice")} </th>
                <th> {t("tel-practice")} </th>
                <th> {t("postal-practice")} </th>
                <th> {t("contact-practice")} </th>
                <th> {t("city-practice")} </th>
                <th> {t("mail-practice")} </th>
                <th> {"Status"} </th>
              </tr>
            </thead>

            {practices.length > 0 && (practices.map((p, index) => {
              return (
                <tbody key={index}>
                  <tr>
                    <th>{p.name}</th>
                    <th>{p.street}</th>
                    <th>{p.telephone}</th>
                    <th>{p.postal_code}</th>
                    <th>{p.contact_person}</th>
                    <th>{p.country}</th>
                    <th>{p.email_accounting}</th>
                    <th>
                      {p.invisible && !p.moderation_pending ? "Denied" : !p.invisible && !p.moderation_pending ? "Approved": "Pending"}
                    </th>
                  </tr>
                </tbody>

              )
            })

            )}

          </Table>
        </div>)}

    </>
  );
}

TablePractices.defaultProps = {
  practices: [],
  loading: true
};

TablePractices.propTypes = {
  practices: PropTypes.array,
  loading: PropTypes.bool
};

export default translate("practice-table")(TablePractices);