import React, {useState} from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import { Pagination,PaginationItem, PaginationLink } from "reactstrap";


const Paginator = (props) => {

    const { totalPages , currentPage, filter, callback} = props;
    const { t } = props;
   
    const handleClick =(e, index) =>{    

          callback(index,filter) //send current page
             
      }

  return (
    <>
      <nav aria-label="paginator lists">
        <Pagination>
          <PaginationItem disabled={currentPage <= 0 } >
            <PaginationLink onClick={e => handleClick(e,currentPage - 1)}>
            {t("previous-practice")} 
            </PaginationLink>
          </PaginationItem>

           

          {[...Array(totalPages)].map((page, i) => 
              <PaginationItem active={i === currentPage} key={i}>
                <PaginationLink onClick={e => handleClick(e, i)} href="#">
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            )}
            
          <PaginationItem disabled={currentPage >= totalPages - 1}> 
            <PaginationLink onClick={e => handleClick(e,currentPage + 1)}>
            {t("next-practice")} 
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </nav>
    </>
  );
};
Paginator.defaultProps = {
    totalPages: 1,
    currentPage:0,
    pageSize:1,
    filter:null//allow us to indicate to paginator if is all data or search results
  };
  
  Paginator.propTypes = {
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    filter: PropTypes.string,
    callback: PropTypes.func
  };

export default translate("paginator-search")(Paginator);
