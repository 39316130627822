import { takeEvery, put, select, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import CONSTANTS from '../CONSTANTS';
import historyRouter from 'historyRouter';
//Services
import BillingService from '../../services/BillingService';
//Actions
import { actionSetBilling,  actionSetBillingByPractice} from '../actions/billingActions';

/**
 * Get a practice
 * @param {json} practice
 */
function* sagaBilling({ days }) {
  try {
    const result = yield call(BillingService.getBilling,days);
    if (result.status >= 400) {
      throw new Error(result.response);
    }
    yield put(actionSetBilling(result.data));
  } catch (error) {
    // console.log(error);
    // toast.error(error.message || 'Unauthorized');
  }
}

function* sagaBillingByPractices({days}) {
  try {
    const result = yield call(BillingService.getBillingByPractice,days);
    if (result.status >= 400) {
      throw new Error('Get System Billing by practice failed');
    }
    yield put(actionSetBillingByPractice(result.data));
  } catch (error) {
    //toast.error(error.message || 'Get invitations failed');
  }
}

export function* billingSaga() {
  console.log('*Main Billing Saga');
  yield takeEvery(CONSTANTS.BILLING_GET_ALL, sagaBilling);
  yield takeEvery(CONSTANTS.BILLING_GET_ALL_BY_PRACTICE, sagaBillingByPractices);
}
