import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import { translate } from "react-translate";

const Search= (props) => {

    const { placeholder, callback } = props;
    const [searchTerm, setSearchTerm] = React.useState("");
    const { t } = props;

    const handleChange = e => {
        setSearchTerm(e.target.value);
    };

    const clearInput = () => {
        setSearchTerm('');
        callback(null)
    };

    useEffect(() => {

        if (searchTerm.length > 2) {
            callback(0, searchTerm);//call to the search service only if we type more than two characters
        }

        if (searchTerm == "") {
            callback(null);//if the input text doesn't have characters refresh all data

        }
    }, [searchTerm]);

    return (
        <>
            <div className="type_msg ctrol_width">
                    <div className="input_user_search">
                        <input
                            type="text"
                            className="user_search"
                            id="user_search_field"
                            onKeyUp={(e) => {

                            }}
                            placeholder={t(placeholder)}
                            value={searchTerm}
                            onChange={handleChange}

                        />
                        {searchTerm.length > 0 && (
                        <button className="msg_send_btn" type="button" onClick={clearInput}>
                            <span className="icon-trash"></span>
                        </button>
                        )}

                </div>
            </div>
            <br></br>
        </>
    );
};
Search.defaultProps = {
    placeholder: "search"
};

Search.propTypes = {
    placeholder: PropTypes.string,
    callback: PropTypes.func
};


export default translate("practice-search")(Search);
