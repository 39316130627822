import { takeEvery, put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import momentjsI18n from "helpers/momentjsI18n";
//Redux Actions
import CONSTANTS from "../CONSTANTS";
import {
  actionLogin,
  actionSetUser,
  actionGetUser,
  actionSetQr2fa,
  actionSetLoadingUsers,
  actionSetLang,
  actionSetDateFormat,
  actionSetUsersDoctorList,
  actionSetUsersPatientList
} from "../actions/usersActions";
import { actionSetLoading } from "store/actions/loadingActions";
import { actionResetApp } from "store/actions/rootActions";
//FIXME: TEST DATA
import userTest from "variables/user_test";
//Services
import UsersDoctorService from '../../services/UsersDoctorService';
import UsersPatientService from "services/UsersPatientService";
import AuthService from "../../plugins/TS-lib-utils-public/services/AuthService.js";
import HL7MetricsService from "services/HL7MetricsService";
import historyRouter from 'historyRouter';
AuthService.setBaseUrl("/management");

function* sagaGetUser() {
  try {
    yield put(actionSetLoading(true));
    let user = yield call(AuthService.getUser);

    console.log(user);
    if (user.status === 401 || !user?.data) {
      yield call(AuthService.forceRemoveToken());
      historyRouter.push('/');
      throw new Error("Unauthorized");
    }
    if (user.data) {
      yield put(actionSetUser(user.data));
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(actionSetLoading(false));
  }
}

function* sagaRegister({ user }) {
  try {
    //Register
    const result = yield AuthService.register(user);
    if (result && result.status === 201) {
      yield put(actionLogin(user));
    }
  } catch (error) {
    console.log(error);
  }
}

function* sagaLogout() {
  try {
    yield AuthService.logout();
    yield put(actionResetApp());
    localStorage.removeItem("tsm-token");
  } catch (error) {
    console.log(error);
  }
}

function* sagaLogin({ user }) {
  try {
    //yield put(actionSetLoading(true));
    // toast.info("Starting");
    let login = yield call(AuthService.login, user);
    if (login.status === 'pending') {
      return yield put(actionSetUser({ ...login, auth_2fa: login.status }));
    }
    if (login.status === 'success') {
      return yield put(actionSetUser(login));
    }
    throw new Error('Login Unauthorized')
  } catch (error) {
    toast.error('Login Unauthorized');
    console.log(error.message || 'Bad request');
    historyRouter.push("/auth/login-page");
  } finally {
    //yield put(actionSetLoading(false));
  }
}

function* sagaCheckSession() {
  try {
    yield call(AuthService.checkSession);
  } catch (error) {
    console.log(error);
  }
}

//Auth2fa
function* sagaCheck2fa({ code }) {
  try {
    toast.info("Checking code");
    const check2fa = yield AuthService.check2fa(code);
    if (check2fa.status === 200) {
      yield put(actionGetUser());
    } else {
      toast.error("failed!");
    }
  } catch (error) {
    toast.error("Bad request");
  }
}

function* sagaActivate2fa({ code }) {
  try {
    const activate2fa = yield AuthService.activate2fa(code);
    if (activate2fa.status === 200) {
      toast.success("Successfuly activation!");
      yield put(actionGetUser());
    } else {
      toast.error("Failed activation!");
    }
  } catch (error) {
    console.log(error);
  }
}
function* sagaInactivate2fa({ pass }) {
  try {
    const inactivate2fa = yield AuthService.inactivate2fa(pass);
    if (inactivate2fa.status === 200) {
      yield put(actionGetUser());
      toast.success("Auth 2fa was disabled");
    } else {
      toast.error("Auth 2fa was not disabled");
    }
  } catch (error) {
    toast.error("Bad request!");
  }
}

function* sagaCreate2fa() {
  try {
    yield put(actionSetLoadingUsers(true));
    const auth2fa = yield AuthService.create2fa();
    if (auth2fa.data.key) {
      yield put(
        actionSetQr2fa({ key: auth2fa.data.key, uri: auth2fa.data.uri })
      );
    }
    yield put(actionSetLoadingUsers(false));
  } catch (error) {
    toast.error("2FA Creation");
  }
}

function* sagaUpdateLang({ language }) {
  try {
    const user = { language };
    const result = yield AuthService.edit(user);
    if (result && result.status === 200) {
      toast.success("User data updated");
      yield put(actionSetLang(language));
    }
  } catch (error) {
    toast.error("Update failed");
  }
}

function* sagaUpdateDateFormat({ dateFormat }) {
  try {
    const user = { dateFormat };
    const result = yield AuthService.edit(user);
    if (result && result.status === 200) {
      toast.success("User data updated");
      momentjsI18n.setFormat(dateFormat);
      yield put(actionSetDateFormat(dateFormat));
    }
  } catch (error) {
    toast.error("Update failed");
  }
}
function* sagaGetUsersDoctor({ page, limit, search }) {
  try {
    const result = yield call(UsersDoctorService.getUserDoctor, page,limit,search);
    if (result.status >= 400) {
      throw new Error('Get users failed');
    }
    yield put(actionSetUsersDoctorList(result.data.data));
  } catch (error) {
    toast.error(error.message || 'Get doctors failed');
  }
}

function* sagaGetUsersPatient({ page, limit, search }) {
  try {
    const result = yield call(UsersPatientService.getUserPatient, page,limit,search);
    if (result.status >= 400) {
      throw new Error('Get patients failed');
    }
    yield put(actionSetUsersPatientList(result.data.data));
  } catch (error) {
    toast.error(error.message || 'Get patients failed');
  }
}

function* sagaUpdateDoctorUser({ data }) {
  try {
    const result = yield call(UsersDoctorService.updateUser, data);
    if (result.status >= 400) {
      throw new Error("User edition failed");
    }
    toast.success("User edited successfully");
  } catch (e) {
    toast.error(e.message || 'User edition failed');
  }
}

export function* usersSaga() {
  // console.log("*Main Users Saga");
  yield takeEvery(CONSTANTS.USERS_LOGIN, sagaLogin);
  yield takeEvery(CONSTANTS.USERS_LOGOUT, sagaLogout);
  yield takeEvery(CONSTANTS.USERS_CHECK_SESSION, sagaCheckSession);
  yield takeEvery(CONSTANTS.USERS_REGISTER, sagaRegister);
  yield takeEvery(CONSTANTS.USERS_GET, sagaGetUser);
  //Auth2fa
  yield takeEvery(CONSTANTS.AUTH2FA_CREATE, sagaCreate2fa);
  yield takeEvery(CONSTANTS.AUTH2FA_ACTIVATE, sagaActivate2fa);
  yield takeEvery(CONSTANTS.AUTH2FA_INACTIVATE, sagaInactivate2fa);
  yield takeEvery(CONSTANTS.AUTH2FA_CHECK, sagaCheck2fa);
  //Settings
  yield takeEvery(CONSTANTS.USERS_UPDATE_LANG, sagaUpdateLang);
  yield takeEvery(CONSTANTS.USERS_UPDATE_DATE_FORMAT, sagaUpdateDateFormat);
  
  yield takeEvery(CONSTANTS.USERS_DOCTOR_GET_LIST, sagaGetUsersDoctor);
  yield takeEvery(CONSTANTS.USERS_DOCTOR_FORM_SUBMIT, sagaUpdateDoctorUser);

  yield takeEvery(CONSTANTS.USERS_PATIENTS_GET_LIST, sagaGetUsersPatient);
}
