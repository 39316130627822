/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Col,
  FormGroup,
  Input,
} from "reactstrap";

// core components
import avatar from "assets/img/emilyz.jpg";

import bgImage from "assets/img/bg13.jpg";
import { actionCheck2fa } from "store/actions/usersActions";

const LockScreenPage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer);

  // eslint-disable-next-line
  const [code, setCode] = useState(null);
  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setCode(value);
    if (value.length === 6) {
      dispatch(actionCheck2fa(value));
    }
  };

  useEffect(() => {
    document.body.classList.add("lock-page");
    return () => {
      document.body.classList.remove("lock-page");
    };
    // eslint-disable-next-line
  }, []);

  if (!user.auth_2fa) {
    return <Redirect to="/auth" />;
  } else if (user.auth_2fa === "true") {
    return <Redirect to="/admin" />;
  }

  return (
    <>
      <div className="content">
        <div className="login-page">
          <Container>
            <Col lg={4} md={8} xs={12} className="mr-auto ml-auto">
              <Card className="card-lock text-center">
                <CardHeader>
                  <img src={avatar} alt="avatar-img" />
                </CardHeader>
                <CardBody>
                  <CardTitle tag="h4">{user.name}</CardTitle>
                  <FormGroup>
                    <Input
                      className="text-center"
                      type="number"
                      placeholder="Enter code 2fa..."
                      onChange={handleChange}
                    />
                  </FormGroup>
                </CardBody>
                {/* <CardFooter>
                  <Button color="primary" size="lg" className="btn-round">
                    Unlock
                  </Button>
                </CardFooter> */}
              </Card>
            </Col>
          </Container>
        </div>
      </div>
      <div
        className="full-page-background"
        style={{
          backgroundImage: "url(" + bgImage + ")",
        }}
      />
    </>
  );
};

export default LockScreenPage;
