import AxiosService from "plugins/TS-lib-utils-public/services/AxiosService"; 

class UsersPatientService extends AxiosService {
    constructor() {
        super("users");
    }
  
    getUserPatient = async (page, limit, search = null) => {
        let query;

        if (search) {
            query = `/patients/list?page=${page}&limit=${limit}&search=${search}`;
        } else {
            query = `/patients/list?page=${page}&limit=${limit}`;
        }

        const response = await this.get(
            query,
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            }
        );
        return response;
    };

}

export default new UsersPatientService();
