import CONSTANTS from '../CONSTANTS';

const initialState = {
  lastAction: "",
  clientList: []
}

const websocketReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.WS_SET_DATA:
      return {...state, lastAction: action.data };
    case CONSTANTS.WS_SET_CLIENTLIST:
      return {...state, clientList: action.data.sort((a,b) => a.firstName > b.firstName) };
    default:
      return state;
  }
};

export default websocketReducer;
