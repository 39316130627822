import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import PropTypes from "prop-types";
import { Table, Button } from 'reactstrap';
import { translate } from "react-translate";
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';

import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';

const TableLogs = (props) => {
    const { data, loading } = props;
    const { t } = props;
    const dispatch = useDispatch();
    const history = useHistory();

    const replaceMessage = (message, keys) => {
        try {
            return t(message, keys).replace('logsDetail.','');
        } catch(e) {
            console.log(e);
            return message;
        }
    }

    return (
        <>
            {loading ?
                (<LoadingLocal />)
                :
                (<div className="table-data">
                    <Table id="logs">
                        <thead>
                            <tr>
                                <th> {t("message")}</th>
                                <th> {t("environment")}</th>
                                <th> {t("ip")}</th>
                                <th> {t("created")}</th>
                            </tr>
                        </thead>

                        {data.length > 0 && (data.map((p, index) => {
                            let geo = JSON.parse(p.geographics);
                            let session = JSON.parse(p.session);
                            let keys = JSON.parse(p.keys);
                            let created = moment(p.created).format('YYYY-MM-DD HH:mm:ss SSS');
                            let divice = (session && session.user && session.user.device && session.user.device.user_agent) ? session.user.device.user_agent:t('unknown');
                            let clase = p.severity ?? p.level;
                            if(clase === 'error'){
                                clase = 'danger';
                            }
                            let username = (session && session.user && session.user.username) ? session.user.username:t('unknown');
                            let auth_email = (session && session.user && session.user.auth_email) ? session.user.auth_email:t('unknown');
                            let first_name = (session && session.user && session.user.firstName) ? session.user.firstName:t('unknown');
                            let last_name = (session && session.user && session.user.lastName) ? session.user.lastName:t('unknown');
                            let type = (session && session.user && session.user.type) ? session.user.type:t('unknown');
                            divice = divice.replaceAll(')', ')\u00a0\u00a0\u00a0\u00a0\n');
                            return (
                                <tbody key={index}>
                                    <tr class={clase}>
                                        <td>    
                                            {p.user !== '000000000000000000000000' && (
                                            <>
                                            <span><b>{t(type)}:</b> {first_name}, {last_name}</span><br />
                                            <span><b>{t("username")}:</b> {username}</span><br />
                                            <span><b>{t("mail")}:</b> <a href={`mailto:${auth_email}`}>{auth_email}</a></span><br />
                                            <br />
                                            </>
                                            )}
                                            {p.user === '000000000000000000000000' && (
                                            <>
                                            <span><b>{t("system_message")}:</b></span>
                                            <br />
                                            </>
                                            )}
                                            {p.module && (
                                            <>
                                            <span><b>{t("Module").replace('logsDetail.','')}:</b>{p.module}</span><br />
                                            </>
                                            )}
                                            {replaceMessage(p.message, keys)}
                                        </td>
                                        <td>
                                            <small>
                                                <span><b>{t("referer")}:</b> {p.referer}</span><br />
                                                <span style={{ whiteSpace: 'pre-line' }}><b>{t("device")}:</b> {divice}</span><br />
                                            </small>
                                        </td>
                                        <td>
                                            <b>{p.ip}</b><br />
                                            <small>
                                                {geo.ll[0] != 0 && (
                                                <>
                                                <span>{geo.city} / {geo.region} / {geo.country}</span><br />
                                                <span>{t("tz")}: {geo.timezone}</span><br />
                                                <span>{t("lat")}: {geo.ll[0]} {t("lon")}: {geo.ll[1]}</span>
                                                </>
                                                )}
                                            </small>
                                        </td>
                                        <td>{created}</td>
                                    </tr>
                                </tbody>
                            )
                        })

                        )}
                    </Table>
                </div>)}

        </>
    );
};
TableLogs.defaultProps = {
    data: [],
    loading: true
};

TableLogs.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool
};
export default translate("logsDetail")(TableLogs);