import CONSTANTS from "../CONSTANTS";

export const actionSetPractices = (data) => ({
  type: CONSTANTS.PRACTICES_SET_LIST,
  data
});

export const actionGetPractices = (page = 0, limit = 5, search = null) => ({
  type: CONSTANTS.PRACTICES_GET_LIST,
  page,
  limit,
  search  
});

//Moderation
export const actionGetPendingPractices = () => ({
  type: CONSTANTS.PRACTICES_GET_MODLIST
});

export const actionSetPendingPractices = (practices) => ({
  type: CONSTANTS.PRACTICES_SET_MODLIST,
  practices
});

export const actionResolveModerationState = (practiceId, status) => ({
  type: CONSTANTS.PRACTICES_SOLVE_MODERATION,
  practiceId,
  status
});

//Count practices
export const actionGetAllPractices= (days = 1) => ({
  type: CONSTANTS.PRACTICES_GET_ALL,
  days
});
export const actionSetAllPractices = (totalPractices) => ({
  type: CONSTANTS.PRACTICES_SET_ALL,
  totalPractices,
});

