import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { translate } from "react-translate";
import ListAnomalies from 'components/ListAnomalies/ListAnomalies';

const Anomalies = (props) => {
  const {t} = props;
  
  return (
    <div className='content user-manual'>
      <Card>
        <CardHeader>
          <h5 className="title">{t("title-header")}</h5>
        </CardHeader>
        <CardBody>
          <ListAnomalies />
        </CardBody>
      </Card>
    </div>
  );
};

export default translate("anomalies")(Anomalies);
