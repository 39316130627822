import { takeLatest, put, call, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";

//Redux Actions
import CONSTANTS from "../CONSTANTS";
import AnomaliesService from "services/AnomaliesService";
import { actionGetAnomalyEdit, actionSetAnomaliesList, actionSetAnomalyEdit } from "../actions/anomaliesActions";

function* sagaGetAnomaliesList({ page, limit, search }) {
    try {
      const result = yield call(AnomaliesService.getAnomaliesList, page, limit, search);
      if (result.status >= 400) {
        throw new Error('Get anomalies failed');
      }
      yield put(actionSetAnomaliesList(result.data));
    } catch (error) {
      toast.error(error.message || 'Get anomalies failed');
    }
}

function* sagaEditAnomaly({ data }) {
  try {
    const result = yield call(AnomaliesService.editAnomaly, data.anomalyId, data.state, data.comment);
    if (result.status >= 400) {
      throw new Error('Edit anomalies failed');
    }
    yield put(actionGetAnomalyEdit(data.anomalyId));
    toast.success(`Anomaly edited succesfully!`);
  } catch (error) {
    toast.error(error.message || 'Edit anomaly failed');
  }
}

function* sagaGetAnomaly({ anomalyId }) {
  try {
    const response = yield call(AnomaliesService.getAnomaly, anomalyId);
    if (response.status >= 400 ) {
      throw new Error("Error obtaining the study");
    }
    yield put(actionSetAnomalyEdit(response.data));
  } catch (error) {
    console.log(error);
    toast.error(error.message || 'Edit anomaly failed');
  }
}

function* sagaResendStudy({ anomalyId, hl7Id }) {
  try {
    const response = yield call(AnomaliesService.resendStudy, anomalyId, hl7Id);
    if (response.status >= 400) {
      throw new Error("Error marking the study");
    }
    yield put(actionGetAnomalyEdit(anomalyId));
    toast.success("Study marked for resend!");
  } catch (error) {
    console.log(error);
    toast.error(error.message || 'Edit anomaly failed');
  }
}

export function* anomaliesSaga() {
    yield takeLatest(CONSTANTS.ANOMALIES_GET_LIST, sagaGetAnomaliesList);
    yield takeLatest(CONSTANTS.ANOMALIES_SUBMIT_FORM, sagaEditAnomaly);
    yield takeLatest(CONSTANTS.ANOMALIES_GET_EDIT, sagaGetAnomaly);
    yield takeEvery(CONSTANTS.ANOMALIES_RESEND, sagaResendStudy);
}
  