import React, { useEffect } from 'react';
import { translate } from "react-translate";

import { useDispatch, useSelector } from "react-redux";
import { actionGetAllDoctorsSystem, actionGetAllPatientsSystem, actionGetAllHl7Consent } from "store/actions/systemActions";
import { actionGetAllPractices } from "store/actions/practicesActions";
import { actionGetBilling } from "store/actions/billingActions";

import WidgetCount from '../../views/Widgets/WidgetCount';
import WidgetLastTime from '../../views/Widgets/WidgetLastTime';
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { Row } from "reactstrap";

const DashboardContent = (props) => {

    const dispatch = useDispatch();
    const days= 7;
    const { t } = props;
    const dataDoctors = useSelector(state => state.systemReducer.systemDoctors);
    const dataPatients = useSelector(state => state.systemReducer.systemPatients);
    const dataPractices = useSelector(state => state.practicesReducer.totalPractices);
    const dataConsent = useSelector(state => state.systemReducer.systemHL7Consent);
    const dataBilling = useSelector(state => state.billingReducer.studies);

    useEffect(() => {
        dispatch(actionGetAllPatientsSystem(days));
        dispatch(actionGetAllDoctorsSystem(days));
        dispatch(actionGetAllPractices(days));
        dispatch(actionGetBilling(days));
        dispatch(actionGetAllHl7Consent());
    }, [dispatch]);

    return (
        <React.Fragment>
            <PanelHeader size="sm" />
            <div className="content">
                <Row>
                    <WidgetCount amount={dataDoctors.users} type={t("users-widget")} title={''} icon={'icon-user'} />
                    <WidgetCount amount={dataPatients.users} type={"patients"} title={''} icon={'icon-user'} />
                    <WidgetCount amount={dataPractices.practices} type={t('practices-widget')} title={''} icon={'icon-hospital'} />
                </Row>
                <Row>
                    <WidgetCount amount={dataPatients.from_BOS} type={"from BOS"} title={''} icon={'icon-user'} />
                    <WidgetCount amount={dataPatients.from_PIFS} type={"from PIFS"} title={''} icon={'icon-user'} />
                    <WidgetCount amount={dataPatients.from_form} type={"from register from"} title={''} icon={'icon-user'} />
                </Row>
                <hr />
                <Row>
                    <WidgetLastTime total ={dataDoctors.users} amount={dataDoctors.newUsers} type={t("users-widget")} title={t("last-widget")}  days={days.toString()} />
                    <WidgetLastTime total ={dataPatients.users} amount={dataPatients.newUsers} type={"Patients"} title={t("last-widget")}  days={days.toString()} />
                    <WidgetLastTime total ={dataPractices.practices} amount={dataPractices.newPractices}  type={t('practices-widget')}  title={t("last-widget")}  days={days.toString()} />
                    <WidgetLastTime total ={dataBilling.studies}   type={t('billing-widget')}  title={t("last-widget")}  days={days.toString()} />
                </Row>
                <hr />
                <Row>
                    <WidgetCount amount={dataConsent.approved} type={"Consent Approved"} icon={'icon-hospital'} />
                    <WidgetCount amount={dataConsent.denied} type={"Consent denied"} icon={'icon-hospital'} />
                </Row>
            </div>
        </React.Fragment>
    );
};

export default translate("widgets")(DashboardContent);
