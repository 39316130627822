import CONSTANTS from '../CONSTANTS';

const initialState = {
  anomalies: [],
  anomalyEdit: {}
};

const anomaliesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.ANOMALIES_SET_LIST:
      return { ...state, anomalies: action.anomalies };
    case CONSTANTS.ANOMALIES_SET_EDIT:
      return { ...state, anomalyEdit: action.anomaly };
    default:
      return state;
  }
};

export default anomaliesReducer;