import { takeEvery, put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
//Redux Actions
import CONSTANTS from "../CONSTANTS";
import { actionSetSystemRoutes, actionSetAllDoctorsSystem, actionSetAllPatientsSystem, actionSetAllHl7Consent } from "../actions/systemActions";
//Services
import SystemService from "services/SystemService.js";
import SystemUsersService from 'services/SystemUsersService';
import HL7MetricsService from "services/HL7MetricsService";

/**
 * Read Many
 */
function* sagaSystemRoutes({ host }) {
  try {
    const routes = yield call(SystemService.getMSRoutes);
    let data = {};
    routes.forEach((route) => {
      data = Object.keys(route).length > 0 ? route : data;
    });
    yield put(actionSetSystemRoutes(data));
  } catch (error) {
    // console.log(error);
    toast.error(error.message || "Unauthorized");
  }
}
function* sagaGetAllDoctorsSystem({days}) {
  try {
    const result = yield call(SystemUsersService.getAllSystemDoctors,days);
    if (result.status >= 400) {
      throw new Error('Get System doctors failed');
    }
    yield put(actionSetAllDoctorsSystem(result.data));
  } catch (error) {
    toast.error(error.message);
  }
}

function* sagaGetAllPatientsSystem({days}) {
  try {
    const result = yield call(SystemUsersService.getAllSystemPatients,days);
    if (result.status >= 400) {
      throw new Error('Get System patients failed');
    }
    yield put(actionSetAllPatientsSystem(result.data));
  } catch (error) {
    toast.error(error.message);
  }
}

function* sagaGetAllHL7Consent({}) {
  try {
    const result = yield call(HL7MetricsService.getAllMetrics);
    if (result.status >= 400) {
      throw new Error('Get System patients failed');
    }
    yield put(actionSetAllHl7Consent(result.data));
  } catch (error) {
    toast.error(error.message);
  }
}

export function* systemSaga() {
  // console.log("*Main System Saga");
  yield takeEvery(CONSTANTS.SYSTEM_GET_ROUTES, sagaSystemRoutes);
  yield takeEvery(CONSTANTS.SYSTEM_GET_DOCTORS_ALL, sagaGetAllDoctorsSystem);
  yield takeEvery(CONSTANTS.SYSTEM_GET_PATIENTS_ALL, sagaGetAllPatientsSystem);
  yield takeEvery(CONSTANTS.SYSTEM_GET_HL7_CONSENT, sagaGetAllHL7Consent);
}
