import { takeEvery, put, call } from "redux-saga/effects";
import { toast } from "react-toastify";

//Redux Actions
import CONSTANTS from "../CONSTANTS";
import AnalysisService from "services/AnalysisService";
import { actionSetAnalysisList } from "../actions/analysisActions";

function* sagaGetAnalysis({ page, limit, search }) {
    try {
      const result = yield call(AnalysisService.getAnalysis, page, limit, search);
      if (result.status >= 400) {
        throw new Error('Get analysis failed');
      }
      yield put(actionSetAnalysisList(result.data.data));
    } catch (error) {
      toast.error(error.message || 'Get analysis failed');
    }
}

export function* analysisSaga() {
    yield takeEvery(CONSTANTS.ANALYSIS_GET_LIST, sagaGetAnalysis);
}
  