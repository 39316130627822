/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { AppName } from "configs/app.json";
import DashboardContent from 'components/DashboardContent/DashboardContent';

// reactstrap components
import { Card, CardBody, Col, Row, CardHeader } from "reactstrap";
const Dashboard = () => {
  return (
    <div className="content dashboard">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <DashboardContent/>
            </CardHeader>
            <CardBody></CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
