import { takeEvery, put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
//Redux Actions
import CONSTANTS from "../CONSTANTS";
import {
  actionSetModules,
  actionSetModule,
  actionGetModules,
  actionGetModule,
} from "../actions/authorizationActions";
//Services
import ModulesService from "services/ModulesService.js";

// Modules

/**
 * Read Many
 */
function* sagaModules() {
  try {
    let modules = yield call(ModulesService.readMany);
    if (modules.status >= 400) {
      throw new Error("Bad request");
    }
    yield put(actionSetModules(modules.data));
  } catch (error) {
    console.log(error);
    toast.error(error.message || "Unauthorized");
  }
}

/**
 * Read One
 */
function* sagaModule({ id }) {
  try {
    let module = yield call(ModulesService.readOne, id);
    if (module.status >= 400) {
      throw new Error("Bad request");
    }
    yield put(actionSetModule(module.data));
  } catch (error) {
    console.log(error);
    toast.error(error.message || "Unauthorized");
  }
}
/**
 * Save
 */
function* sagaModulesSave({ data }) {
  try {
    let module = null;
    if (!data._id) {
      // new
      module = yield call(ModulesService.create, data);
      if (module.status >= 400) {
        throw new Error("Bad request");
      }
      if (module) {
        toast.success("Module created");
      } else {
        toast.error("Module create failed");
      }
    } else {
      // edit
      module = yield call(ModulesService.update, data);
      if (module.status >= 400) {
        throw new Error("Bad request");
      }
      yield put(actionGetModule(data._id));
      toast.success("Module updated");
    }
    yield put(actionSetModule(module));
    yield put(actionGetModules());
  } catch (error) {
    toast.error(error.message || "Unauthorized");
  }
}
/**
 * Save action
 */
function* sagaActionsSave({ id, data }) {
  try {
    let module = null;
    if (!data._id) {
      // new
      module = yield call(ModulesService.addAction, id, data);
      if (module.status >= 400) {
        throw new Error("Bad request");
      }
      if (module) {
        toast.success("Action created");
      } else {
        toast.error("Action create failed");
      }
    } else {
      // edit
      module = yield call(ModulesService.updateAction, id, data);
      if (module.status >= 400) {
        throw new Error("Bad request");
      }
      toast.success("Module updated");
    }
    yield put(actionSetModule(module));
    yield put(actionGetModules());
  } catch (error) {
    toast.error(error.message || "Unauthorized");
  }
}

/**
 * Delete
 */
function* sagaModulesDelete({ id }) {
  try {
    const module = yield call(ModulesService.remove, id);
    if (module.status >= 400) {
      throw new Error("Bad request");
    }
    yield put(actionGetModules());
    toast.success("Module has deleted");
  } catch (error) {
    toast.error(error.message || "Unauthorized");
  }
}
/**
 * Delete
 */
function* sagaActionsDelete({ module_id, action_id }) {
  try {
    const module = yield call(ModulesService.deleteAction, module_id, action_id);
    if (module.status >= 400) {
      throw new Error("Bad request");
    }
    yield put(actionGetModules());
    toast.success("Module has deleted");
  } catch (error) {
    toast.error(error.message || "Unauthorized");
  }
}

/**
 * Clear
 */
// function* sagaModuleClear({ module }) {
//   try {
//     if (Object.keys(module).length === 0) {
//     }
//   } catch (error) {
//     toast.error(error.message || "Unauthorized");
//   }
// }

export function* modulesSaga() {
  // console.log("*Main Modules Saga");
  yield takeEvery(CONSTANTS.MODULES_GET, sagaModules);
  yield takeEvery(CONSTANTS.MODULES_GET_ONE, sagaModule);
  yield takeEvery(CONSTANTS.MODULES_SAVE, sagaModulesSave);
  yield takeEvery(CONSTANTS.MODULES_DELETE, sagaModulesDelete);
  yield takeEvery(CONSTANTS.MODULES_ACTIONS_SAVE, sagaActionsSave);
  yield takeEvery(CONSTANTS.MODULES_ACTIONS_DELETE, sagaActionsDelete);
  // yield takeEvery(CONSTANTS.MODULES_SET_ONE, sagaModuleClear);
}
