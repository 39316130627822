import AxiosService from "plugins/TS-lib-utils-public/services/AxiosService"; 

class LogsService extends AxiosService {
    constructor() {
        super('log');
    }
  
    getLogs = async (page, limit, search = null) => {
        let query;
        if (search) {
            query = `/get-all/?page=${page}&limit=${limit}&search=${search}`;
        } else {
            query = `/get-all/?page=${page}&limit=${limit}`;
        }
        const response = await this.get(
            query,
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            }
        );
        return response;
    };

}
export default new LogsService();
