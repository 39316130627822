import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from "react-redux";
import { actionGetPractices } from "store/actions/practicesActions";

import TablePractices from './TablePractices';
import TablePendingPractices from "./TablePendingPractices"

import Search from '../../components/Common/Search'
import Paginator from '../../components/Common/Paginator';
import Loading from "plugins/TS-lib-utils-public/components/Loading/LoadingGlobal";
import classnames from 'classnames';

import {
    Nav,
    NavItem,
    NavLink,
  } from 'reactstrap';
import { actionGetPendingPractices } from 'store/actions/practicesActions';


const ListPractices = () => {

    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState('1');
    const { practices } = useSelector(state => state.practicesReducer.practices);
    const pendingPractices = useSelector(state => state.practicesReducer.pendingPractices);
    const { pagination } = useSelector(state => state.practicesReducer.practices);
    const [loading, setloading] = useState(true);
    const [loadingLocal, setloadingLocal] = useState(false);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);//number of practices per page
    const [totalPage, setTotalPage] = useState(0);
    const [filter, setFilter] = useState(null);//search input text 

    useEffect(() => {
        dispatch(actionGetPractices(page,limit,filter));
        dispatch(actionGetPendingPractices());

    }, [activeTab]);

    useEffect(() => {

        if (practices && pagination) {
            setloading(false);
            setloadingLocal(false);
            setPage(pagination._page);
            setLimit(pagination._limit);
            setTotalPage(pagination._total_pages);

        }

    }, [dispatch, practices]);


   
    //method called by search
    function changeDataSearch(page, filter) {  
        setloadingLocal(true)
        if(filter == null){
            //if the search type "" refresh all data
            setFilter(null);
            dispatch(actionGetPractices(page,limit));
        }else{
            //we save search input text, therefore we will only paginate the search results
            setFilter(filter);
            dispatch(actionGetPractices(page,limit,filter)); 
        }   
    }

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
      };

    return (
        <React.Fragment>
            <div>
                <Loading loading={loading} />

                <Nav pills className="nav-pills-primary mb-4">
                <NavItem className="profile-item">
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => {
                      toggle('1');
                    }}>
                    Current Practices
                  </NavLink>
                </NavItem>
                <NavItem className="profile-item">
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => {
                      toggle('2');
                    }}>
                    Moderation
                  </NavLink>
                </NavItem>
              </Nav>
              
              {activeTab === '1' ? (
                <>
                    <Search placeholder={"search-text-practice"} callback={changeDataSearch} />
                    <TablePractices practices={practices} loading={loadingLocal} />
                    <Paginator totalPages={totalPage } currentPage={page} pageSize={limit} filter = {filter} callback={changeDataSearch} />
                </>
              ) : (
                <>
                    <TablePendingPractices practices={pendingPractices} loading={loadingLocal} />
                </>
              )}
                

            </div>

        </React.Fragment>
    );
};

export default ListPractices;

