import AxiosService from './AxiosService';

class AuthService extends AxiosService {
  constructor() {
    super('auth');
  }

  login = async (user) => {
    try {
      let result = await this.post(`/login`, user);
      if (result.status === 200) {
        this.setToken(result.data.token);
        result = result.data;
      } else {
        return {
          status: result.response.status,
          statusText: result.response.statusText,
          message: result.response.data,
        };
      }
      return result;
    } catch (error) {
      return {
        status: error.response.status,
        statusText: error.response.statusText,
      };
    }
  };

  logout = async () => {
    try {
      const token = this.getToken();
      if (!token) {
        return null;
      }
      this.removeToken();
      return await this.get(`/logout`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      return {
        status: error.response.status,
        statusText: error.response.statusText,
      };
    }
  };

  getSessionList = async () => {
    const token = this.getToken();
      if (!token) {
        return null;
      }
    const response = await this.get(
      `/session`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }

  deleteSession = async (sessionToken) => {
    const response = await this.delete(
      `/session`,
      {
        headers: {
          Authorization: `Bearer ${sessionToken}`
        },
      }
    );
    return response;
  }

  forceRemoveToken = () => {
    this.removeToken();
  }

  getUser = async () => {
    try {
      const token = this.getToken();
      if (!token) {
        return null;
      }
      let result = await this.get(`/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return result;
    } catch (error) {
      this.removeToken();
      return {
        status: error.response.status,
        statusText: error.response.statusText,
      };
    }
  };

  register = async (user) => {
    return await this.post(`/`, user);
  };

  edit = async (user) => {
    try {
      const token = this.getToken('-token');
      return await this.put(`/`, user, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      return {
        status: error.response.status,
        statusText: error.response.statusText,
      };
    }
  };

  checkSession = async () => {
    try {
      const token = this.getToken();
      if (!token) {
        return false;
      }
      let result = await this.get(`/check_session/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return result.status * 1;
    } catch (error) {
      return {
        status: error.response.status,
        statusText: error.response.statusText,
      };
    }
  };

  getSession = async (key = 'user') => {
    try {
      const token = this.getToken();
      if (!token) {
        return null;
      }
      return await this.get(`/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      return {
        status: error.response.status,
        statusText: error.response.statusText,
      };
    }
  };

  create2fa = async () => {
    try {
      const token = this.getToken();
      if (!token) {
        return null;
      }
      return await this.get(`/2fa/create`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      return {
        status: error.response.status,
        statusText: error.response.statusText,
      };
    }
  };

  activate2fa = async (code) => {
    try {
      const token = this.getToken();
      if (!token) {
        return null;
      }
      return await this.post(
        `/2fa/activate`,
        { code: code },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      return {
        status: error.response.status,
        statusText: error.response.statusText,
      };
    }
  };

  inactivate2fa = async (pass) => {
    try {
      const token = this.getToken();
      if (!token) {
        return null;
      }
      return await this.post(
        `/2fa/inactivate`,
        { code: 'code', password: pass },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      return {
        status: error.response.status,
        statusText: error.response.statusText,
      };
    }
  };

  check2fa = async (code) => {
    try {
      const token = this.getToken();
      if (!token) {
        return null;
      }
      return await this.post(
        `/2fa/check`,
        { code: code },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      return {
        status: error.response.status,
        statusText: error.response.statusText,
      };
    }
  };

  changePassword = async (user) => {
    const token = this.getToken();
    if (!token) {
      return null;
    }
    return await this.put(`/change-password`, user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  recoverPassword = async (email) => {
    try {
      return await this.post(`/recovery`, email);
    } catch (error) {
      return {
        status: error.response.status,
        statusText: error.response.statusText,
      };
    }
  };

  requestAccountDeletion = async (userType) => {
    try {
      const token = this.getToken();
      return await this.post(`/delete-request`, {
        type: userType
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
    } catch (error) {
      return {
        status: error.response.status,
        statusText: error.response.statusText,
      };
    }
  };

  prepareAccountDeletion = async (userType, user) => {
    try {
      return await this.post(`/delete-prepare`, {
        type: userType,
        user
      });
    } catch (error) {
      return {
        status: error.response.status,
        statusText: error.response.statusText,
      };
    }
  };

  confirmAccountDeletion = async (userType, userId, choice) => {
    try {
      return await this.post(`/delete`, {
        type: userType,
        userId,
        choice
      }, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        }
      });
    } catch (error) {
      return {
        status: error.response.status,
        statusText: error.response.statusText,
      };
    }
  };
}

export default new AuthService();
