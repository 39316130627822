import CONSTANTS from "../CONSTANTS";

const initialState = {
  functionalities: [],
  functionality: {},
  available: [],
  allowed: [],
};

const functionalitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.FUNCTIONALITIES_SET:
      return { ...state, functionalities: action.functionalities };
    case CONSTANTS.FUNCTIONALITIES_SET_ONE:
      return { ...state, functionality: action.functionality };
    case CONSTANTS.FUNCTIONALITIES_SET_AVAILABLE:
      return { ...state, available: action.functionalities };
    case CONSTANTS.FUNCTIONALITIES_SET_ALLOWED:
      return { ...state, allowed: action.functionalities };
    default:
      return state;
  }
};

export default functionalitiesReducer;
