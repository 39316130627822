import { createStore, combineReducers, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
//Sagas

import { usersSaga } from "./sagas/usersSaga";
import { logsSaga } from "./sagas/logsSaga";
import { analysisSaga } from "./sagas/analysisSaga";
import { rolesSaga } from "./sagas/rolesSaga";
import { modulesSaga } from "./sagas/modulesSaga";

// import { actionsSaga } from "./sagas/actionsSaga";
import { systemSaga } from "./sagas/systemSaga";
import { manualSaga } from "./sagas/manualSaga";
import { practicesSaga } from './sagas/practicesSaga';
import { wsSaga } from './sagas/wsSaga';
import { billingSaga } from './sagas/billingSaga';
import { anomaliesSaga } from "./sagas/anomaliesSaga";
//Reducers
import loadingReducer from "./reducers/loadingReducer";
import userReducer from "./reducers/userReducer";
import commonReducer from "./reducers/commonReducer";
import treeReducer from "./reducers/treeReducer";
import rolesReducer from "./reducers/rolesReducer";
import functionalitiesReducer from "./reducers/functionalitiesReducer";
import modulesReducer from "./reducers/modulesReducer";
import practicesReducer from './reducers/practicesReducer';
import billingReducer from './reducers/billingReducer';
// import actionsReducer from "./reducers/actionsReducer";
import systemReducer from "./reducers/systemReducer";
import manualReducer from "./reducers/manualReducer";
import userDoctorsReducer from "./reducers/userDoctorsReducer";
import logsReducer from "./reducers/logsReducer";
import analysisReducer from "./reducers/analysisReducer";
import usersPatientsReducer from "./reducers/userPatientsReducer";
import websocketReducer from "./reducers/websocketReducer";
import anomaliesReducer from "./reducers/anomaliesReducer";

const allReducers = combineReducers({
  loadingReducer,
  userReducer,
  commonReducer,
  treeReducer,
  rolesReducer,
  functionalitiesReducer,
  modulesReducer,
  practicesReducer,
  billingReducer,
  // actionsReducer,
  websocketReducer,
  systemReducer,
  manualReducer,
  userDoctorsReducer,
  usersPatientsReducer,
  logsReducer,
  analysisReducer,
  anomaliesReducer
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_APP") {
    state = undefined;
  }
  return allReducers(state, action);
};

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
sagaMiddleware.run(analysisSaga);
sagaMiddleware.run(anomaliesSaga);
sagaMiddleware.run(logsSaga);
sagaMiddleware.run(usersSaga);
sagaMiddleware.run(rolesSaga);
// sagaMiddleware.run(functionalitiesSaga);
sagaMiddleware.run(modulesSaga);
// sagaMiddleware.run(actionsSaga);
sagaMiddleware.run(systemSaga);
sagaMiddleware.run(manualSaga);
sagaMiddleware.run(practicesSaga);
sagaMiddleware.run(billingSaga);
sagaMiddleware.run(wsSaga);

export default store;
