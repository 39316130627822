/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { useState } from "react";

// reactstrap components
import { Card, CardHeader, CardBody } from "reactstrap";
import CsvReader from "components/Charts/CsvReader";
import ChartLine from "components/Charts/ChartLine";

const Charts = () => {
  const [dataSet, setDataSet] = useState([]);

  //Range values for validations
  const ranges = {
    SpO2: {
      min: 85,
      max: 100,
    },
    "Pulse Rate": {
      min: 40,
      max: 150,
    },
  };

  return (
    <React.Fragment>
      <div className="content">
        <Card>
          <CardHeader className="d-flex justify-content-between align-content-center">
            <h5 className="title">CSV Viewer</h5>
          </CardHeader>
          <CardBody>
            <CsvReader setData={setDataSet} ranges={ranges} />
            {/* Charts */}
            {Array.isArray(dataSet) && dataSet.length > 0 && (
              <Card className="card-chart">
                <CardBody>
                  <div className="chart-wrapper">
                    {dataSet.map((chart, i) => {
                      if (i > 0) {
                        return (
                          <ChartLine
                            chart={chart}
                            labels={dataSet[0].data}
                            ranges={ranges}
                            key={i}
                          />
                        );
                      }
                    })}
                  </div>
                </CardBody>
              </Card>
            )}
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Charts;
