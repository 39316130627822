import React, { Suspense, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import PropTypes from "prop-types";
import LoadingLocal from "plugins/TS-lib-utils-public/components/Loading/LoadingLocal";
const RoleForm = React.lazy(() => import("./RoleForm"));
const ModuleForm = React.lazy(() => import("./ModuleForm"));
const ActionForm = React.lazy(() => import("./ActionForm"));

const ModalAuthButton = (props) => {
  const { buttonLabel, className, buttonSize, type, title, moduleId } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Button color="info" size={buttonSize} onClick={toggle}>
        {buttonLabel}
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={className} centered>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          {type === "action" && (
            <Suspense fallback={<LoadingLocal />}>
              <ActionForm moduleId={moduleId} toggle={toggle} />
            </Suspense>
          )}
          {type === "module" && (
            <Suspense fallback={<LoadingLocal />}>
              <ModuleForm toggle={toggle} />
            </Suspense>
          )}
          {type === "role" && (
            <Suspense fallback={<LoadingLocal />}>
              <RoleForm toggle={toggle} />
            </Suspense>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

ModalAuthButton.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  className: PropTypes.string,
  buttonSize: PropTypes.oneOf(["sm", "md", "lg"]),
  type: PropTypes.oneOf(["action", "module", "role"]),
};

export default ModalAuthButton;
