import React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Label,
  FormGroup,
  Form,
  Input,
  Button,
} from 'reactstrap';
import { useDispatch } from "react-redux";
import { useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { actionAnomalySubmitForm } from 'store/actions/anomaliesActions';
import { translate } from "react-translate";
import { actionResendStudy } from 'store/actions/anomaliesActions';

const AnomaliesEditForm = (props) => {
  const dispatch = useDispatch();
  const { t } = props;

  //States
  const anomalyData = useSelector(state => state.anomaliesReducer.anomalyEdit);
  
  const { register, handleSubmit } = useForm({
    defaultValues: {
      state: anomalyData.state,
      comment: anomalyData.comment
    }
  });
  const history = useHistory();

  const onSubmit = (data) => {
    dispatch(actionAnomalySubmitForm({...data, anomalyId: anomalyData._id}));
    history.goBack();
  };

  const resend = () => {
    if (anomalyData && anomalyData._id) {
      dispatch(actionResendStudy(anomalyData._id, anomalyData.hl7_id));
    }
  }

  return (
    <div className="content user-manual-edit mt-3">

      <Card>
        <CardHeader>{`Editing anomaly ${anomalyData.order}${anomalyData.control}...`}</CardHeader>
        <CardBody>


        <Form id="user-edit-form" onSubmit={handleSubmit(onSubmit)}>

          <div className="d-flex justify-content-between" >
            <table border="0" className="metadata-table">
                <thead>
                    <tr>
                        <th style={{color: anomalyData.case && !anomalyData.case.patient ? "red" : ""}}>
                            <b>{"Patient ID"}: </b> {anomalyData.patient_pifs_id}
                        </th>
                        <th style={{color: anomalyData.case && !anomalyData.case.doctor ? "red" : ""}}>
                            <b>{"Doctor ID"}: </b> {anomalyData.doctor_pifs_id}
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr>    
                        <td><b>{t("order")}: </b> {anomalyData.order}</td>
                        <td><b>{t("control")}: </b> {anomalyData.control}</td>
                    </tr>
                    
                    <tr>
                        <td><b>{t("anomaly-date")}: </b> {anomalyData.anomaly_date}</td>
                        <td><b>{t("practice")}: </b> {anomalyData.practice}</td>
                    </tr>

                    <tr>
                        <td>
                          <b>{t("status")}: </b>
                            <div className="d-inline-block">
                            <FormGroup>
                              <Input
                                type="select"
                                name="state"
                                required
                                innerRef={register}
                              > 
                                <option value="active">Active</option>
                                <option value="closed">Closed</option>
                                <option value="in progress">In progress</option>
                              </Input>
                            </FormGroup>
                          </div>
                        </td>

                        <td> 
                            <Button 
                              size="md"
                              color="info"
                              onClick={() => {resend()}}
                            >
                              Resend Study
                            </Button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <table border="0" className="metadata-table">
                <thead>
                    <tr>
                        <th>
                            Resend history
                        </th>
                    </tr>
                </thead>

                <tbody>
                      { anomalyData.resends && anomalyData.resends.map(s => {
                        return (<tr><td><b>{s.sent_at}</b></td></tr>);
                      })}
                </tbody>
            </table>
          </div>
            

            <Label>Comment</Label>
            <FormGroup>
              <Input
                type="textarea"
                name="comment"
                placeholder="Comment..."
                innerRef={register}
              ></Input>
            </FormGroup>
          </Form>

          <Button form="user-edit-form" size="sm" color="info" type="submit">
            Update
          </Button>

          <Button
            form="user-edit-form"
            size="sm"
            color="danger"
            type="reset"
            onClick={(e) => {
              e.preventDefault();
              history.goBack();
            }}
          >
            Cancel
          </Button>
        </CardBody>
      </Card>
    </div>
  );
};

export default translate("anomalies")(AnomaliesEditForm);
