import React from "react";
import "./css/loadingLocal.css";

const LoadingLocal = () => {
  return (
    <div className="spinner">
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  );
};

export default LoadingLocal;
