import CONSTANTS from '../CONSTANTS';

export const actionSetLastData = (data) => ({
  type: CONSTANTS.WS_SET_DATA,
  data,
});

export const actionSetClientList = (data) => ({
  type: CONSTANTS.WS_SET_CLIENTLIST,
  data,
});

export const actionGetClientList = () => ({
  type: CONSTANTS.WS_GET_CLIENTLIST
});