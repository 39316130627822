const CONSTANTS = {
  //App
  RESET_APP: "RESET_APP",
  //Common
  SWEET_ALERT: "SWEET_ALERT",
  COLLAPSE: "COLLAPSE",
  //System
  SYSTEM_GET_ROUTES: "SYSTEM_GET_ROUTES",
  SYSTEM_SET_ROUTES: "SYSTEM_SET_ROUTES",
  SYSTEM_GET_DOCTORS_ALL: "SYSTEM_GET_DOCTORS_ALL",
  SYSTEM_SET_DOCTORS_ALL: "SYSTEM_SET_DOCTORS_ALL",
  SYSTEM_GET_PATIENTS_ALL: "SYSTEM_GET_PATIENTS_ALL",
  SYSTEM_SET_PATIENTS_ALL: "SYSTEM_SET_PATIENTS_ALL",
  SYSTEM_GET_HL7_CONSENT: "SYSTEM_GET_HL7_CONSENT",
  SYSTEM_SET_HL7_CONSENT: "SYSTEM_SET_HL7_CONSENT",

  //Tree
  TREE_DOCTORS_SET: "TREE_DOCTORS_SET",
  //Users
  USERS_LOGIN: "USERS_LOGIN",
  USERS_LOGOUT: "USERS_LOGOUT",
  USERS_CHECK_SESSION: "USERS_CHECK_SESSION",
  USERS_REGISTER: "USERS_REGISTER",
  USERS_GET: "USERS_GET",
  USERS_SAVE: "USERS_SAVE",
  USERS_SET: "USERS_SET",
  USERS_EDIT: "USERS_EDIT",
  USERS_LOADING: "USERS_LOADING",
  USERS_AUTHORIZATION_SET: "USERS_AUTHORIZATION_SET",
  WS_SET_CLIENTLIST: 'WS_SET_CLIENTLIST',
  WS_GET_CLIENTLIST: 'WS_GET_CLIENTLIST',
  //Users settings
  USERS_SET_LANG: "USERS_SET_LANG",
  USERS_UPDATE_LANG: "USERS_UPDATE_LANG",
  USERS_SET_DATE_FORMAT: "USERS_SET_DATE_FORMAT",
  USERS_UPDATE_DATE_FORMAT: "USERS_UPDATE_DATE_FORMAT",
  //Users Doctors
  USERS_DOCTOR_GET_LIST: 'USERS_DOCTOR_GET_LIST',
  USERS_DOCTOR_SET_LIST: 'USERS_DOCTOR_SET_LIST',
  USERS_DOCTOR_GET_EDIT_DATA: "USERS_DOCTOR_GET_EDIT_DATA",
  USERS_DOCTOR_SET_EDIT_DATA: "USERS_DOCTOR_SET_EDIT_DATA",
  USERS_DOCTOR_FORM_SUBMIT: "USERS_DOCTOR_FORM_SUBMIT",
  //User patients
  USERS_PATIENTS_GET_LIST: 'USERS_PATIENTS_GET_LIST',
  USERS_PATIENTS_SET_LIST: 'USERS_PATIENTS_SET_LIST',
  //Logs
  LOGS_GET_LIST: 'LOGS_GET_LIST',
  LOGS_SET_LIST: 'LOGS_SET_LIST',
  //Analysis
  ANALYSIS_GET_LIST: 'ANALYSIS_GET_LIST',
  ANALYSIS_SET_LIST: 'ANALYSIS_SET_LIST',
  //Auth2fa
  AUTH2FA_CREATE: "AUTH2FA_CREATE",
  AUTH2FA_SET: "AUTH2FA_SET",
  AUTH2FA_ACTIVATE: "AUTH2FA_ACTIVATE",
  AUTH2FA_INACTIVATE: "AUTH2FA_INACTIVATE",
  AUTH2FA_CHECK: "AUTH2FA_CHECK",
  //Loading
  LOADING: "LOADING",
  //Authorization
  AUTHORIZATION_BACKUP: "AUTHORIZATION_BACKUP",
  //ROLES
  ROLES_SET: "ROLES_SET",
  ROLES_SET_ONE: "ROLES_SET_ONE",
  ROLES_SAVE: "ROLES_SAVE",
  ROLES_GET: "ROLES_GET",
  ROLES_GET_ONE: "ROLES_GET_ONE",
  ROLES_DELETE: "ROLES_DELETE",
  //FUNCTIONALITIES
  // FUNCTIONALITIES_SET: "FUNCTIONALITIES_SET",
  // FUNCTIONALITIES_SET_ONE: "FUNCTIONALITIES_SET_ONE",
  // FUNCTIONALITIES_SAVE: "FUNCTIONALITIES_SAVE",
  // FUNCTIONALITIES_GET: "FUNCTIONALITIES_GET",
  // FUNCTIONALITIES_GET_ONE: "FUNCTIONALITIES_GET_ONE",
  // FUNCTIONALITIES_DELETE: "FUNCTIONALITIES_DELETE",
  // FUNCTIONALITIES_AVAILABLE: "FUNCTIONALITIES_AVAILABLE",
  // FUNCTIONALITIES_SET_AVAILABLE: "FUNCTIONALITIES_SET_AVAILABLE",
  // FUNCTIONALITIES_ALLOWED: "FUNCTIONALITIES_ALLOWED",
  // FUNCTIONALITIES_SET_ALLOWED: "FUNCTIONALITIES_SET_ALLOWED",
  //MODULES
  MODULES_SET: "MODULES_SET",
  MODULES_SET_ONE: "MODULES_SET_ONE",
  MODULES_SAVE: "MODULES_SAVE",
  MODULES_GET: "MODULES_GET",
  MODULES_GET_ONE: "MODULES_GET_ONE",
  MODULES_DELETE: "MODULES_DELETE",
  MODULES_AVAILABLE: "MODULES_AVAILABLE",
  MODULES_SET_AVAILABLE: "MODULES_SET_AVAILABLE",
  MODULES_ALLOWED: "MODULES_ALLOWED",
  MODULES_SET_ALLOWED: "MODULES_SET_ALLOWED",
  //ACTIONS
  MODULES_ACTIONS_SAVE: "MODULES_ACTIONS_SAVE",
  MODULES_ACTIONS_DELETE: "MODULES_ACTIONS_DELETE",
  ACTIONS_SET: "ACTIONS_SET",
  ACTIONS_SET_ONE: "ACTIONS_SET_ONE",
  ACTIONS_SAVE: "ACTIONS_SAVE",
  ACTIONS_GET: "ACTIONS_GET",
  ACTIONS_GET_ONE: "ACTIONS_GET_ONE",
  ACTIONS_DELETE: "ACTIONS_DELETE",
  ACTIONS_AVAILABLE: "ACTIONS_AVAILABLE",
  ACTIONS_SET_AVAILABLE: "ACTIONS_SET_AVAILABLE",
  ACTIONS_ALLOWED: "ACTIONS_ALLOWED",
  ACTIONS_SET_ALLOWED: "ACTIONS_SET_ALLOWED",
  //MANUAL
  MANUAL_GET_ALL: "MANUAL_GET_ALL",
  MANUAL_SET_ALL: "MANUAL_SET_ALL",
  MANUAL_DELETE_KEY: "MANUAL_DELETE_KEY",
  MANUAL_EDIT_KEY: "MANUAL_EDIT_KEY",
  MANUAL_GET_KEY: "MANUAL_GET_KEY",
  MANUAL_SET_KEY: "MANUAL_SET_KEY",
  MANUAL_CREATE_KEY: "MANUAL_CREATE_KEY",
  //Practices
  PRACTICES_GET_LIST: 'PRACTICES_GET_LIST',
  PRACTICES_SET_LIST: 'PRACTICES_SET_LIST',
  PRACTICES_GET_ALL: "PRACTICES_GET_ALL",
  PRACTICES_SET_ALL: "PRACTICES_SET_ALL",
  PRACTICES_GET_MODLIST: "PRACTICES_GET_MODLIST",
  PRACTICES_SET_MODLIST: "PRACTICES_SET_MODLIST",
  PRACTICES_SOLVE_MODERATION: "PRACTICES_SOLVE_MODERATION",
  //Billing
  BILLING_GET_ALL: 'BILLING_GET_ALL',
  BILLING_SET_ALL: 'BILLING_SET_ALL',
  BILLING_GET_ALL_BY_PRACTICE: 'BILLING_GET_ALL_BY_PRACTICE',
  BILLING_SET_ALL_BY_PRACTICE: 'BILLING_SET_ALL_BY_PRACTICE',

  //Anomalies
  ANOMALIES_RESEND: 'ANOMALIES_RESEND',
  ANOMALIES_GET_LIST: 'ANOMALIES_GET_LIST',
  ANOMALIES_SET_LIST: 'ANOMALIES_SET_LIST',
  ANOMALIES_GET_EDIT: 'ANOMALIES_GET_EDIT',
  ANOMALIES_SET_EDIT: 'ANOMALIES_SET_EDIT',
  ANOMALIES_SUBMIT_FORM: 'ANOMALIES_SUBMIT_FORM'
};

export default CONSTANTS;
