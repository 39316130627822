import AxiosService from "plugins/TS-lib-utils-public/services/AxiosService";

class HL7Metrics extends AxiosService {
    constructor() {
        super("management");
    }

    getAllMetrics = async () => {
        try {
            let result = await this.get(`/hl7/metrics`, {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                }
            });
            return result;
        } catch (error) {
            return {
                status: error.response.status,
                statusText: error.response.statusText,
            };
        }
    };

    getAll = async () => {
        try {
            let result = await this.get(`/hl7/metrics`, {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                }
            });
            return result;
        } catch (error) {
            return {
                status: error.response.status,
                statusText: error.response.statusText,
            };
        }
    };
}

export default new HL7Metrics();