import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";


const Switch = (props) => {
    const { label , callback, filter } = props;
    const [isChecked, setIsChecked] = React.useState("");

    const handleToggle = () => {
        setIsChecked(!isChecked);
    };

    useEffect(() => { callback(isChecked, filter); }, [isChecked, filter]);

    return (
        <label className="switch">
          <input
            type="checkbox"
            className="switch_search"
            id="switch_search_field"
            checked={isChecked}
            onChange={handleToggle}
          />
          <span className="slider"> {label}</span>
        </label>
      ); 
};

Switch.propTypes = {
    label: PropTypes.string,
    callback: PropTypes.func
};

export default Switch;
