import React, { useState } from "react";
//import { useSelector, useDispatch } from "react-redux";
//import { actionSetLang } from "store/actions/usersActions";
//import languages from "configs/lang";
import Icon from "components/Icons/Icon";
import { useDispatch } from "react-redux";
import { actionLogout } from "store/actions/usersActions";

import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";

function UserMenu(props) {
  const dispatch = useDispatch();
  const [dropdownOpenUser, setdropdownOpenUser] = useState(false);

  const dropdownToggleUser = () => {
    setdropdownOpenUser(!dropdownOpenUser);
  };

  // User Logout
  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(actionLogout());
  };

  const handleSettings = (e) => {
    e.preventDefault();
    props.history.push("/admin/settings");
  };

  return (
    <Dropdown nav isOpen={dropdownOpenUser} toggle={dropdownToggleUser}>
      <DropdownToggle caret nav>
        <Icon file="user-header.svg" width="21px" title="User" />
        <p className="ml-2">
          <span className="d-lg-none d-md-block">Account</span>
        </p>
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem tag="a" href="#" onClick={handleSettings}>
          Settings
        </DropdownItem>

        <DropdownItem tag="a" href="#" onClick={handleLogout}>
          Logout
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export default UserMenu;
