import React from 'react';
import PropTypes from "prop-types";
import { Button, Table } from 'reactstrap';
import { translate } from "react-translate";
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { actionSetAnomalyEdit } from 'store/actions/anomaliesActions';

const TableAnomalies = (props) => {
    const { data, loading } = props;
    const { t } = props;
    const dispatch = useDispatch();
    const history = useHistory();

    const editAnomaly = (e, anomaly) => {
        e.preventDefault();
        dispatch(actionSetAnomalyEdit({...anomaly}));
        history.push(`/admin/anomalies/edit`);
    }

    return (
        <>
            {loading ?
                (<LoadingLocal />)
                :
                (<div className="table-data">
                    <Table>
                        <thead>
                            <tr>
                                <th> {t("anomaly-date")}</th>
                                <th> {t("order")}</th>
                                <th> {t("control")}</th>
                                <th> {t("doctor-id")}</th>
                                <th> {t("patient-id")}</th>
                                <th> {t("status")} </th>
                                <th> {t("comment")} </th>
                                <th></th>
                            </tr>
                        </thead>

                        {data.length > 0 && (data.map((p, index) => {
                            return (
                                <tbody key={index}>
                                    <tr>
                                        <th>{p.anomaly_date}</th>
                                        <th>{p.control}</th>
                                        <th>{p.order}</th>
                                        <th>{p.doctor_pifs_id}</th>
                                        <th>{p.patient_pifs_id}</th>
                                        <th>{p.state}</th>
                                        <th>
                                            <p>
                                                {p.comment}
                                            </p>
                                        </th>
                                        <th>
                                            <Button
                                                onClick={e => editAnomaly(e, p)}
                                                className="btn-icon btn-round"
                                                color="warning"
                                                size="md"
                                            >
                                                <i className="fa fa-edit" />
                                            </Button>
                                        </th>
                                    </tr>
                                </tbody>
                            )
                        })
                        )}
                    </Table>
                </div>)}

        </>
    );
};
TableAnomalies.defaultProps = {
    data: [],
    loading: true
};

TableAnomalies.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool
};

export default translate("anomalies")(TableAnomalies);