import AxiosService from "plugins/TS-lib-utils-public/services/AxiosService";

class SystemUsersService extends AxiosService {
  constructor() {
    super("users");
  }

  getAllSystemDoctors = async (days) => {
    let query;

    query = `/statistics?days=${days}`;

    const response = await this.get(
      query,
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      }
    );
    return response;
  };

  getAllSystemPatients = async (days) => {
    let query;

    query = `/patients/statistics?days=${days}`;

    const response = await this.get(
      query,
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      }
    );
    return response;
  };


}

export default new SystemUsersService();
