import React, { useEffect, useRef } from 'react';
import PropTypes from "prop-types";
import { Table, Button } from 'reactstrap';
import { translate } from "react-translate";
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';
import { actionSetEditData } from 'store/actions/usersActions';
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';

const TableDoctorUsers = (props) => {
    const { data, loading } = props;
    const { t } = props;
    const dispatch = useDispatch();
    const history = useHistory();

    const editUser = (e, user) => {
        e.preventDefault();
        dispatch(actionSetEditData({...user}));
        history.push(`/admin/list-users/edit/`);
    }

    return (
        <>
            {loading ?
                (<LoadingLocal />)
                :
                (<div className="table-data">
                    <Table>
                        <thead>
                            <tr>
                                <th> {t("firstName-user")}</th>
                                <th> {t("lastName-user")}</th>
                                <th> {t("email-user")}</th>
                                <th> {t("position-user")}</th>
                                <th> {t("phone-user")}</th>
                                <th> {t("location-user")}</th>
                                <th> {t("type-user")}</th>
                                <th> {t("actions")} </th>
                            </tr>
                        </thead>

                        {data.length > 0 && (data.map((p, index) => {
                            return (
                                <tbody key={index}>
                                    <tr>
                                        <th>{p.firstName}</th>
                                        <th>{p.lastName}</th>
                                        <th>{p.email}</th>
                                        <th>{p.position}</th>
                                        <th>{p.phone}</th>
                                        <th>{p.location}</th>
                                        <th>{p.membership}</th>
                                        <th>
                                        <Button
                                            onClick={e => editUser(e, p)}
                                            className="btn-icon btn-round"
                                            color="warning"
                                            size="md"
                                        >
                                            <i className="fa fa-edit" />
                                        </Button>
                                        </th>
                                    </tr>
                                </tbody>

                            )
                        })

                        )}

                    </Table>
                </div>)}

        </>
    );
};
TableDoctorUsers.defaultProps = {
    data: [],
    loading: true
};

TableDoctorUsers.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool
};

export default translate("userList-table")(TableDoctorUsers);