import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import { translate } from "react-translate";

import { actionGetLogsList } from 'store/actions/logsActions';
import TableLogs from './TableLogs';
import Paginator from '../../components/Common/Paginator';
import Search from '../../components/Common/Search'
import Switch from '../../components/Common/Switch';
import Loading from "plugins/TS-lib-utils-public/components/Loading/LoadingGlobal";

const ListLogs = (props) => {
    const dispatch = useDispatch();
    const { t } = props;
    const { logs } = useSelector(state => state.logsReducer.logs);
    const { pagination } = useSelector(state => state.logsReducer.logs);
    const [loading, setloading] = useState(false);
    const [loadingLocal, setloadingLocal] = useState(false);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(20);
    const [totalPage, setTotalPage] = useState(0);
    const [filter, setFilter] = useState(null);
    var   interval = false;
    var   activeFilter = '';

    useEffect(() => {
        dispatch(actionGetLogsList(page, limit));
    }, []);

    useEffect(() => {
        if (logs && pagination) {
            setloading(false);
            setloadingLocal(false);
            setPage(pagination._page);
            setLimit(pagination._limit);
            setTotalPage(pagination._total_pages);
        }
    }, [dispatch, logs]);

    //Callback del componente Check
    function intervalOnOff(isChecked, filter){
       if(ListLogs.interval){
            stopAutoRefresh();
       }

       if(isChecked === true){
            ListLogs.activeFilter = filter;
            startAutoRefresh(filter);
       }else{
            stopAutoRefresh();
       }
    }

    //Inicio el intervalo
    function startAutoRefresh(filter){
        ListLogs.interval = setInterval(() => {
            changeDataSearch(0, ListLogs.activeFilter);
        }, 2000);
    }

    //Detengo el intervalor.
    function stopAutoRefresh(){
        clearInterval(ListLogs.interval);
    }

    //method called by paginator and search
    function changeDataSearch(page, filter) {
        //if the search type "" refresh all data
        setloadingLocal(false)
        if (filter == null) {
            setFilter(null);
            dispatch(actionGetLogsList(page, limit));
        } else {
            //we save search input text, therefore we will only paginate the search results
            setFilter(filter);
            dispatch(actionGetLogsList(page, limit, filter));
        }
    }

    return (
        <div className='content user-manual'>           
                <CardBody>
                    <div>
                        <Loading loading={loading} />
                        <Switch label={t("title-real-time")} callback={intervalOnOff} filter={filter}/>
                        <Search placeholder={"search-text-logList"} callback={changeDataSearch} />
                        <TableLogs data={logs} loading={loadingLocal} />
                        <Paginator totalPages={totalPage} currentPage={page} pageSize={limit} filter={filter} callback={changeDataSearch} />
                    </div>
                </CardBody>
        </div>
    );
};
export default translate("logsDetail")(ListLogs);