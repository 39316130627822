/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
// used for making the prop types of this component
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Nav, Collapse, Button } from "reactstrap";

// core components
import avatar from "assets/img/ryan.jpg";
import { actionLogout } from "store/actions/usersActions";
import Icon from "components/Icons/Icon";

var ps;

const Sidebar = (props) => {
  const [openAvatar, setOpenAvatar] = useState(false);
  const [collapseState, setCollapseState] = useState(
    getCollapseStates(props.routes)
  );
  const sidebar = useRef();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer);

  const handleLogout = async () => {
    await dispatch(actionLogout());
  };

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return () => {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      // to stop the warning of calling setState of unmounted component
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, [Object.keys(user).length]);

  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  function getCollapseStates(routes) {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  }
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  function getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  function createLinks(routes) {
    return routes.map((prop, key) => {
      if (prop.invisible) {
        return null;
      }

      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !collapseState[prop.state];
        return (
          <li
            className={getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={collapseState[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                setCollapseState({ ...collapseState, ...st });
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={collapseState[prop.state]}>
              <ul className="nav">{createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }

      return (
        <li className={activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink to={prop.layout + prop.path} activeClassName="">
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  }

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  }
  return (
    <>
      <div className="sidebar" data-color={props.backgroundColor}>
        <div className="logo">
          <a href="#" className="simple-text logo-mini">
            <div className="logo-img">
              <Icon file="logo_TP_initials.svg" alt="TS" width="25px" />
            </div>
          </a>
          <a href="#" className="simple-text logo-normal">
            Telepraxen
          </a>
          <div className="navbar-minimize">
            <Button
              outline
              className="btn-round btn-icon"
              color="neutral"
              id="minimizeSidebar"
              onClick={() => props.minimizeSidebar()}
            >
              <i className="now-ui-icons text_align-center visible-on-sidebar-regular" />
              <i className="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini" />
            </Button>
          </div>
        </div>

        <div className="sidebar-wrapper" ref={sidebar}>
          <div className="user">
            <div className="photo">
              <img src={avatar} alt="Avatar" />
            </div>
            <div className="info">
              <a
                style={{ cursor: "pointer" }}
                // href="#"
                data-toggle="collapse"
                aria-expanded={openAvatar}
                onClick={() => setOpenAvatar(!openAvatar)}
              >
                <span>
                  {user.isAuth && user.first_name}
                  <b className="caret" />
                </span>
              </a>
              <Collapse isOpen={openAvatar}>
                <ul className="nav">
                  {/* <li>
                    <a href="#pablo" onClick={e => e.preventDefault}>
                      <span className="sidebar-mini-icon">MP</span>
                      <span className="sidebar-normal">My Profile</span>
                    </a>
                  </li> */}
                  <li>
                    <a
                      onClick={async (e) => {
                        e.preventDefault();
                        await handleLogout();
                      }}
                    >
                      <span className="sidebar-mini-icon">LO</span>
                      <span className="sidebar-normal">Logout</span>
                    </a>
                  </li>
                  {/* <li>
                    <a onClick={e => props.history.push("/admin/user-page")}>
                      <span className="sidebar-mini-icon">S</span>
                      <span className="sidebar-normal">Settings</span>
                    </a>
                  </li> */}
                  {/* <li>
                    <a onClick={handleLogout}>
                      <span className="sidebar-mini-icon">LG</span>
                      <span className="sidebar-normal">Logout</span>
                    </a>
                  </li> */}
                </ul>
              </Collapse>
            </div>
          </div>
          <Nav>{createLinks(props.routes)}</Nav>
        </div>
      </div>
    </>
  );
};

Sidebar.defaultProps = {
  routes: [],
  showNotification: false,
  backgroundColor: "blue",
  minimizeSidebar: () => {},
};

Sidebar.propTypes = {
  // links that are rendered
  routes: PropTypes.arrayOf(PropTypes.object),
  // if you want to show a notification when switching between mini sidebar and normal
  showNotification: PropTypes.bool,
  // background color for the component
  backgroundColor: PropTypes.oneOf([
    "blue",
    "yellow",
    "green",
    "orange",
    "red",
  ]),
  // function that is called upon pressing the button near the logo
  minimizeSidebar: PropTypes.func,
};

export default Sidebar;
