import AxiosService from 'plugins/TS-lib-utils-public/services/AxiosService';

class PracticesService extends AxiosService {
    constructor() {
        super('practices');
    }

    getPractices = async (page, limit, search = null) => {
        try {
            let result;

            if (search) {
                result = await this.get(`/list/?page=${page}&limit=${limit}&search=${search}`, {
                    headers: {
                        Authorization: `Bearer ${this.getToken()}`,
                    }
                });
            } else {
                result = await this.get(`/list?page=${page}&limit=${limit}`, {
                    headers: {
                        Authorization: `Bearer ${this.getToken()}`,
                    }
                });
            }

            return result;

        } catch (error) {
            return {
                status: error.response.status,
                statusText: error.response.statusText,
            };
        }
    };
    getAllPractices= async (days) => {
        let query;
    
        query = `/statistics?days=${days}`;
    
        const response = await this.get(
          query,
          {
            headers: {
              Authorization: `Bearer ${this.getToken()}`,
            },
          }
        );
        return response;
    };

    getPendingPractices = async () => {
        try {
            return await this.get(`/moderate/`, {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                }
            });
        } catch (error) {
            return {
                status: error.response.status,
                statusText: error.response.statusText,
            };
        }
    };

    resolveModeration = async (practiceId, status) => {
        try {
            return await this.put(`/moderate/`, {status}, {
                headers: {
                    practice_id: practiceId,
                    Authorization: `Bearer ${this.getToken()}`,
                }
            });
        } catch (error) {
            return {
                status: error.response.status,
                statusText: error.response.statusText,
            };
        }
    };

}

export default new PracticesService();
