import React, { useRef } from 'react';
// react plugin used to create charts
import { Line } from 'react-chartjs-2';

import { hexToRGB } from 'variables/charts';

const ChartLine = ({ labels, chart, ranges }) => {
  const chartRef = useRef(null);
  let ticks = {};
  if (ranges[chart.header]) {
    ticks = {
      ...ticks,
      min: ranges[chart.header].min,
      max: ranges[chart.header].max,
      // suggestedMin: ranges[chart.header].min,
      // suggestedMax: ranges[chart.header].max,
    };
  }
  let dataChart = {
    data: (canvas) => {
      var ctx = canvas.getContext('2d');
      var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
      gradientStroke.addColorStop(0, '#18ce0f');
      gradientStroke.addColorStop(1, '#FFFFFF');
      var gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
      gradientFill.addColorStop(0, 'rgba(128, 182, 244, 0)');
      gradientFill.addColorStop(1, hexToRGB('#18ce0f', 0.4));
      return {
        labels,
        datasets: [
          {
            label: chart.header + ' Stats',
            borderColor: '#18ce0f',
            pointBorderColor: '#FFF',
            pointBackgroundColor: '#18ce0f',
            pointBorderWidth: 0,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 1,
            pointRadius: 0,
            fill: true,
            backgroundColor: gradientFill,
            borderWidth: 2,
            data: chart.data,
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      title: {
        display: true,
        position: 'left',
        text: `Min = ${chart.min} / Max = ${chart.max}`,
      },
      legend: {
        display: false,
      },
      tooltips: {
        bodySpacing: 4,
        mode: 'nearest',
        intersect: 0,
        position: 'nearest',
        xPadding: 10,
        yPadding: 10,
        caretPadding: 10,
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            gridLines: {
              zeroLineColor: 'transparent',
              drawBorder: false,
            },
            ticks: {
              maxTicksLimit: 8,
              steps: 20,
              ...ticks,
            },
          },
        ],
        xAxes: [
          {
            type: 'time',
            time: {
              parser: 'hh:mm:ss A',
              tooltipFormat: 'll HH:mm:ss',
            },
            ticks: {
              display: true,
              maxTicksLimit: 10,
              maxRotation: 0,
              padding: 20,
            },
            gridLines: {
              zeroLineColor: 'transparent',
              drawTicks: false,
              display: false,
              drawBorder: false,
            },
          },
        ],
      },
      layout: {
        padding: { left: 0, right: 0, top: 15, bottom: 0 },
      },
    },
  };

  const styleChartArea = {
    height: '190px',
    width: `calc(400% + ${
      Math.round(labels.length) < 10000 ? Math.round(labels.length) : 10000
    }px)`,
    marginLeft: '-15px',
    marginRight: '-15px',
    overflowX: 'auto',
  };

  return (
    <div>
      <h4 className="ml-2">{chart.header}</h4>
      <div style={styleChartArea}>
        <Line
          data={dataChart.data}
          options={dataChart.options}
          ref={(reference) => (chartRef.current = reference)}
        />
      </div>
    </div>
  );
};

export default ChartLine;
