import AxiosService from "plugins/TS-lib-utils-public/services/AxiosService";
class AuthorizationService extends AxiosService {
  constructor(entity) {
    super("practices");
    this.entity = entity;
  }

  /**
   * Get all authorizations
   */
  readMany = async () => {
    const response = await this.get(`/admin/${this.entity}`, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    return response;
  };

  /**
   * Create authorization
   */
  create = async (data) => {
    const response = await this.post(`/admin/${this.entity}`, data, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    return response;
  };

  /**
   * Update authorization
   */
  update = async (data) => {
    const response = await this.put(`/admin/${this.entity}/${data._id}`, data, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    return response;
  };

  /**
   * Read one authorization
   */
  readOne = async (id = null) => {
    const response = await this.get(`/admin/${this.entity}/${id}`, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    return response;
  };

  /**
   * Delete authorization
   */
  remove = async (id) => {
    const response = await this.delete(`/admin/${this.entity}/${id}`, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    return response;
  };

  /**
   * Backup authorization
   */
  backup = async () => {
    const response = await this.get(`/admin/backup`, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    return response;
  };
}

export default AuthorizationService;
