import CONSTANTS from "../CONSTANTS";

export const actionLogin = (user) => ({
  type: CONSTANTS.USERS_LOGIN,
  user,
});

export const actionLogout = () => ({
  type: CONSTANTS.USERS_LOGOUT,
});

export const actionCheckSession = () => ({
  type: CONSTANTS.USERS_CHECK_SESSION,
});

export const actionRegister = (user) => ({
  type: CONSTANTS.USERS_REGISTER,
  user,
});

export const actionUserEdit = (user) => ({
  type: CONSTANTS.USERS_EDIT,
  user,
});

export const actionGetUser = () => ({
  type: CONSTANTS.USERS_GET,
});

export const actionSetUser = (user) => ({
  type: CONSTANTS.USERS_SET,
  user,
});

//Auth2Fa
export const actionCreate2fa = () => ({
  type: CONSTANTS.AUTH2FA_CREATE,
});

export const actionActivate2fa = (code) => ({
  type: CONSTANTS.AUTH2FA_ACTIVATE,
  code,
});

export const actionInactivate2fa = (pass) => ({
  type: CONSTANTS.AUTH2FA_INACTIVATE,
  pass,
});

export const actionCheck2fa = (code) => ({
  type: CONSTANTS.AUTH2FA_CHECK,
  code,
});

export const actionSetQr2fa = (qr) => ({
  type: CONSTANTS.AUTH2FA_SET,
  qr,
});

export const actionSetLoadingUsers = (loading) => ({
  type: CONSTANTS.USERS_LOADING,
  loading,
});
//Settings
// -- Language
export const actionUpdateLang = (language) => ({
  type: CONSTANTS.USERS_UPDATE_LANG,
  language,
});
export const actionSetLang = (language) => ({
  type: CONSTANTS.USERS_SET_LANG,
  language,
});
// -- DateFormat
export const actionSetDateFormat = (dateFormat) => ({
  type: CONSTANTS.USERS_SET_DATE_FORMAT,
  dateFormat,
});

export const actionUpdateDateFormat = (dateFormat) => ({
  type: CONSTANTS.USERS_UPDATE_DATE_FORMAT,
  dateFormat,
});
//Users Doctor
export const actionGetUsersDoctorList = (page = 0, limit = 5 , search=null) => ({
  type: CONSTANTS.USERS_DOCTOR_GET_LIST,
  page,
  limit,
  search
});
export const actionSetUsersDoctorList = (doctors) => ({
  type: CONSTANTS.USERS_DOCTOR_SET_LIST,
  doctors,
});
export const actionSetEditData = (data) => ({
  type: CONSTANTS.USERS_DOCTOR_SET_EDIT_DATA,
  data,
});
export const actionSubmitForm = (data) => ({
  type: CONSTANTS.USERS_DOCTOR_FORM_SUBMIT,
  data,
});
//Users Partient
export const actionGetUsersPatientList = (page = 0, limit = 5 , search=null) => ({
  type: CONSTANTS.USERS_PATIENTS_GET_LIST,
  page,
  limit,
  search
});
export const actionSetUsersPatientList = (patients) => ({
  type: CONSTANTS.USERS_PATIENTS_SET_LIST,
  patients,
});