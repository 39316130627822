import CONSTANTS from "../CONSTANTS";
const initialState = {
  routes: {},
  systemDoctors: {
    users: 0,
    newUsers: 0,
  },
  systemPatients: {
    users: 0,
    newUsers: 0,
    from_BOS: 0,
    from_PIFS: 0
  },
  systemHL7Consent: {
    approved: 0,
    denied: 0
  }
};

const systemReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.SYSTEM_SET_ROUTES:
      return { ...state, routes: { ...state.routes, ...action.routes } };
    case CONSTANTS. SYSTEM_SET_DOCTORS_ALL:
      return { ...state, systemDoctors: action.systemUsers }; 
    case CONSTANTS. SYSTEM_SET_PATIENTS_ALL:
      return { ...state, systemPatients: action.systemUsers }; 
    case CONSTANTS.SYSTEM_SET_HL7_CONSENT:
      return {...state, systemHL7Consent: action.HL7metrics };
    default:
      return state;
  }
};

export default systemReducer;
