import { takeEvery, put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
//Redux Actions
import CONSTANTS from "../CONSTANTS";
import {
  actionSetRoles,
  actionSetRole,
  actionGetRoles,
  actionGetRole,
} from "../actions/authorizationActions";
//Services
import RolesService from "services/RolesService";

// Roles

/**
 * Read Many
 */
function* sagaRoles() {
  try {
    let roles = yield call(RolesService.readMany);
    if (roles.status >= 400) {
      throw new Error("Bad request");
    }
    yield put(actionSetRoles(roles.data));
  } catch (error) {
    console.log(error);
    toast.error(error.message || "Unauthorized");
  }
}

/**
 * Read One
 */
function* sagaRole({ id }) {
  try {
    let role = yield call(RolesService.readOne, id);
    if (role.status >= 400) {
      throw new Error("Bad request");
    }
    yield put(actionSetRole(role.data));
  } catch (error) {
    console.log(error);
    toast.error(error.message || "Unauthorized");
  }
}
/**
 * Save
 */
function* sagaRolesSave({ data }) {
  try {
    let role = null;
    if (!data._id) {
      // new
      role = yield call(RolesService.create, data);
      if (role.status >= 400) {
        throw new Error("Bad request");
      }
      if (role) {
        toast.success("Role created");
      } else {
        toast.error("Role create failed");
      }
      yield put(actionSetRole(role));
    } else {
      // edit
      role = yield call(RolesService.update, data);
      console.log({ role });
      // if (role.status >= 400) {
      //   throw new Error("Bad request");
      // }
      // yield put(actionGetRole(data._id));
      toast.success("Role updated");
    }
    yield put(actionGetRoles());
    return role;
  } catch (error) {
    toast.error(error.message || "Unauthorized");
  }
}

/**
 * Delete
 */
function* sagaRoleDelete({ id }) {
  try {
    const result = yield call(RolesService.remove, id);
    if (result.status >= 400) {
      throw new Error("Role don't has deleted");
    }
    yield put(actionGetRoles());
    toast.success("Role has deleted");
  } catch (error) {
    toast.error(error.message || "Unauthorized");
  }
}

/**
 * Clear
 */
function* sagaRoleClear({ role }) {
  try {
    if (Object.keys(role).length === 0) {
    }
  } catch (error) {
    toast.error(error.message || "Unauthorized");
  }
}

/**
 * General backup
 */
function* sagaAuthorizationBackup() {
  try {
    const result = yield call(RolesService.backup);
    if (result.status >= 400) {
      throw new Error("Backup failed");
    }
    toast.success("Backup complete");
  } catch (error) {
    console.log(error);
    toast.error(error.message || "Unauthorized");
  }
}

export function* rolesSaga() {
  // console.log("*Main Roles Saga");

  yield takeEvery(CONSTANTS.ROLES_GET, sagaRoles);
  yield takeEvery(CONSTANTS.ROLES_GET_ONE, sagaRole);
  yield takeEvery(CONSTANTS.ROLES_SAVE, sagaRolesSave);
  yield takeEvery(CONSTANTS.ROLES_DELETE, sagaRoleDelete);
  yield takeEvery(CONSTANTS.ROLES_SET_ONE, sagaRoleClear);
  yield takeEvery(CONSTANTS.AUTHORIZATION_BACKUP, sagaAuthorizationBackup);
}
