import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { translate } from "react-translate";
import ListAnalysis from 'components/Analysis/ListAnalysis';

const AnalysisDetail = (props) => {
  const {t} = props;

  return (
    <div className='content user-manual'>
      <Card>
        <CardHeader>
          <h5 className="title">{t("title-header")}</h5>
        </CardHeader>
        <CardBody>
          <ListAnalysis />
        </CardBody>
      </Card>
    </div>
  );
}; 

export default translate("analysisDetail")(AnalysisDetail);
