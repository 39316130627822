import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { CardBody } from 'reactstrap';
import { translate } from "react-translate";
import { actionGetAnomaliesList } from 'store/actions/anomaliesActions';
import TableAnomalies from './TableAnomalies';
import Paginator from '../Common/Paginator';
import Search from '../Common/Search'
import Loading from "plugins/TS-lib-utils-public/components/Loading/LoadingGlobal";

const ListAnomalies = (props) => {
    const dispatch = useDispatch();
    const { data } = useSelector(state => state.anomaliesReducer.anomalies);
    const { page, lastPage, limit } = useSelector(state => state.anomaliesReducer.anomalies);
    const [loading, setloading] = useState(false);
    const [loadingLocal, setloadingLocal] = useState(false);
    const [_page, setPage] = useState(0);
    const [_limit, setLimit] = useState(10);
    const [totalPage, setTotalPage] = useState(0);
    const [filter, setFilter] = useState(null);

    useEffect(() => {
        dispatch(actionGetAnomaliesList(_page, _limit));
    }, []);

    useEffect(() => {

        if (data) {
            setloading(false);
            setloadingLocal(false);
            setPage(page);
            setLimit(limit);
            setTotalPage(lastPage);
        }

    }, [dispatch, data]);

    //method called by paginator and search
    function changeDataSearch(page, filter) {
        //if the search type "" refresh all data
        setloadingLocal(true)
        if (filter == null) {
            setFilter(null);
            dispatch(actionGetAnomaliesList(page, _limit));
        } else {
            //we save search input text, therefore we will only paginate the search results
            setFilter(filter);
            dispatch(actionGetAnomaliesList(page, _limit, filter));
        }
    }

    return (
        <div className='content user-manual'>
                <CardBody>
                    <div>
                        <Loading loading={loading} />
                        <Search placeholder={"search-text-logList"} callback={changeDataSearch} />
                        <TableAnomalies data={data} loading={loadingLocal} />
                        <Paginator totalPages={totalPage} currentPage={_page} pageSize={_limit} filter={filter} callback={changeDataSearch} />
                    </div>
                </CardBody>
        </div>
    );
};
export default translate("anomalies")(ListAnomalies);