import AxiosService from 'plugins/TS-lib-utils-public/services/AxiosService';

class BillingService extends AxiosService {
    constructor() {
        super('management');
    }

    getBilling = async (days) => {
        try {
            return await this.get(`/billing/statistics/?days=${days}`, {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                }
            });
        } catch (error) {
            return {
                status: error.response.status,
                statusText: error.response.statusText,
            };
        }
    };

    getBillingByPractice = async (days) => {
        try {
            return await this.get(`/billing/statisticsbypractice/?days=${days}`, {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                }
            });
        } catch (error) {
            return {
                status: error.response.status,
                statusText: error.response.statusText,
            };
        }
    };
}

export default new BillingService();
