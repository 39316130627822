import CONSTANTS from "../CONSTANTS";
//SweetAlert
export const actionSweetAlert = (sweetAlert) => ({
  type: CONSTANTS.SWEET_ALERT,
  sweetAlert,
});
//Collapse
export const actionSetCollapse = (collapse) => ({
  type: CONSTANTS.COLLAPSE,
  collapse,
});
