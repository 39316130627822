import CONSTANTS from "../CONSTANTS";
const initialState = {
  roles: [],
  role: {},
};

const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.ROLES_SET:
      return { ...state, roles: action.roles };
    case CONSTANTS.ROLES_SET_ONE:
      return { ...state, role: action.role };
    default:
      return state;
  }
};

export default rolesReducer;
