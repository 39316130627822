/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState, useRef } from "react";
//import { useDispatch } from "react-redux";
import LanguageSwitch from "components/Languages/LanguageSwitchHeader";
import HelpMenu from "components/Navbars/Menu/HelpMenu.jsx";
import UserMenu from "components/Navbars/Menu/UserMenu.jsx";
import Icon from "components/Icons/Icon";
// used for making the prop types of this component
import PropTypes from "prop-types";

// reactstrap components
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
} from "reactstrap";

import { useCallback } from "react";

const AdminNavbar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState("white");

  const isMounted = useRef(true);

  const sidebarToggle = useRef(null);
  const toggle = () => {
    setColor("white");
    setIsOpen(!isOpen);
  };

  const openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    sidebarToggle.current.classList.toggle("toggled");
  };

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const path_route = props.history.location.pathname;
  const updateColor = useCallback(
    (e) => {
      if (isMounted.current) {
        setColor("white");
      }
      if (typeof e === "undefined") return null;
      let sidebar = sidebarToggle.current;
      if (
        window.innerWidth < 993 &&
        window.location &&
        path_route !== window.location.pathname &&
        document.documentElement.className.indexOf("nav-open") !== -1 &&
        sidebar
      ) {
        document.documentElement.classList.toggle("nav-open");
        sidebar.classList.toggle("toggled");
      }
    },
    [isMounted, path_route]
  );

  useEffect(() => {
    isMounted.current = true;
    window.addEventListener("resize", updateColor);

    return () => {
      isMounted.current = false;
      window.removeEventListener("resize", updateColor);
    };
  }, [isMounted, updateColor]);

  return (
    // add or remove classes depending if we are on full-screen-maps page or not
    <Navbar
      color={
        window.location.href.indexOf("full-screen-maps") !== -1
          ? "white"
          : color
      }
      expand="lg"
      className="navbar-header"

      /*className={
        window.location.href.indexOf("full-screen-maps") !== -1
          ? "navbar-absolute "
          : "navbar-absolute " +
            (color === "transparent" ? "navbar-transparent " : "")

      }*/
    >
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <button
              type="button"
              ref={sidebarToggle}
              className="navbar-toggler"
              onClick={openSidebar}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <NavbarBrand
            href="/"
            className="terminservice-name"
          >
            {/*props.brandText */}
            <Icon file="logo_TP.svg" alt="TS" width="120px" />
          </NavbarBrand>
        </div>

        <NavbarToggler onClick={toggle}>
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </NavbarToggler>

        <Collapse isOpen={isOpen} navbar className="justify-content-end">
          <Nav navbar>
            <LanguageSwitch />
          </Nav>

          <Nav navbar>
            <HelpMenu />
          </Nav>

          <Nav navbar>
            <UserMenu history={props.history} />
          </Nav>

          <Nav navbar>
            <NavItem>
              <NavLink href="!#" className="new-item">
                <Icon file="inbox-header.svg" width="21px" title="Inbox" />
                <p className="ml-2">
                  <span className="d-lg-none d-md-block">Inbox</span>
                </p>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink href="!#" className="new-item">
                <Icon file="message-header.svg" width="21px" title="Inbox" />
                <p className="ml-2">
                  <span className="d-lg-none d-md-block">Chat</span>
                </p>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

AdminNavbar.defaultProps = {
  brandText: "Default Brand Text",
};

AdminNavbar.propTypes = {
  // string for the page name
  brandText: PropTypes.string,
};

export default AdminNavbar;
