import CONSTANTS from '../CONSTANTS';

export const actionGetLogsList = (page = 0, limit = 20 , search=null) => ({
    type: CONSTANTS.LOGS_GET_LIST,
    page,
    limit,
    search
});

export const actionSetLogsList = (logs) => ({
  type: CONSTANTS.LOGS_SET_LIST,
  logs,
});