import CONSTANTS from '../CONSTANTS';
import doctorsTree from 'plugins/TS-lib-utils-public/data/treeWebDoctors.json';
const initialState = {
  doctors: doctorsTree,
};

const treeReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.TREE_DOCTORS_SET:
      return { ...state, doctors: action.doctors };
    default:
      return state;
  }
};

export default treeReducer;
