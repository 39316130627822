import axios from "axios";
import hosts from "configs/hosts.json";
class SystemService {
  /**
   * Get MS routes
   */
  getMSRoutes = async () => {
    try {
      const token = localStorage.getItem("ts-token");
      let obj = {};
      return Promise.all(
        hosts.map(async (host) => {
          return axios
            .get(`${process.env.REACT_APP_HOST}/${host}/system/routes`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((response) => {
              obj[host] =
                response.data && response.data.list ? response.data.list : {};
              return obj;
            })
            .catch(async () => await {});
        })
      );
    } catch (error) {
      // console.log(error);
      // let obj = {};
      // // obj[host.host] = [];
      // return obj;
    }
  };
 
}

export default new SystemService();
