import React, { useRef, useState } from 'react';
import moment from 'moment';
// import fs from "fs";
// import readline from "readline";
// import stream from "stream";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  FormGroup,
  Input,
} from 'reactstrap';
import { toast } from 'react-toastify';

const CsvReader = ({ setData, ranges }) => {
  const [file, setFile] = useState('');

  const inputFile = useRef(null);

  const handleFileInput = () => {
    inputFile.current.click();
  };

  const handleFileRead = (e) => {
    setData([]);
    const file = e.target.files[0];
    if (file && file.name) {
      const reader = new FileReader();
      reader.onload = (evt) => {
        /* Parse data */
        const fileContent = evt.target.result;
        const parseData = parseCSV(fileContent);
        setData(parseData);
      };
      reader.readAsBinaryString(file);
      setFile(file.name);
    } else {
      return toast.error('Invalid file');
    }
  };

  const parseCSV = (text) => {
    // Get lines
    let lines = text.replace(/\r/g, '').split('\n');
    let charts = lines[0].split(',').map((header) => {
      return {
        header,
        data: [],
        min: 99999999999,
        max: -99999999999,
      };
    });
    lines.map((line, i) => {
      // Get value of the lines
      let value = line.split(',');
      if (i > 0) {
        value.foreach((value, j) => {
          //normalize data
          if (value) {
            //only for time labels
            if (j === 0) {
              value = value ? moment(value, 'hh:mm:ss A MMM D YYYY') : null;
            }
            charts[j].data.push(value);
            //Normalize value
            if (j > 0 && charts[j] && ranges[charts[j].header]) {
              value =
                value > ranges[charts[j].header].max
                  ? ranges[charts[j].header].max
                  : value;
              value =
                value < ranges[charts[j].header].min
                  ? ranges[charts[j].header].min
                  : value;
            }
            //calculate min
            charts[j].min = value < charts[j].min ? value : charts[j].min;
            //calculate max
            charts[j].max = value > charts[j].max ? value : charts[j].max;
          }
        });
      }
      return value;
    });
    return charts;
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Select a CSV file</CardTitle>
        </CardHeader>
        <CardBody>
          <FormGroup className="form-file-upload form-file-simple">
            <Input
              type="text"
              className="inputFileVisible"
              placeholder="Select a csv file..."
              onClick={handleFileInput}
              defaultValue={file}
            />
            <input
              type="file"
              className="inputFileHidden"
              accept=".csv"
              style={{ zIndex: -1 }}
              onChange={handleFileRead}
              ref={inputFile}
            />
          </FormGroup>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default CsvReader;
