import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table } from 'reactstrap';
import { useTranslate } from 'react-translate';
import { useSortBy, useTable } from 'react-table';
import { actionGetClientList } from 'store/actions/websocketActions';
import { useHistory } from 'react-router';
import { getWebsocketClientList } from 'store/selectors/selectors';

const WebsocketClients = () => {
  const t = useTranslate('settings');
  const history = useHistory();
  const dispatch = useDispatch();
  const sessions = useSelector(getWebsocketClientList);

  useEffect(() => {

    const id = setInterval(() => {
      dispatch(actionGetClientList());
    }, 1000);

    return () => {
      clearInterval(id);
    }
    //eslint-disable-next-line
  }, []);

  const data = useMemo(
    () => sessions && sessions.length ? sessions.map(p => {

      const sessionData = {
        c1: p.auth_user_id,
        c2: `${p.firstName} ${p.lastName}`,
        c3: p.socketId
      };

      return { ...sessionData};
    }) : [],
    //eslint-disable-next-line
    [sessions, t]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Socket ID",
        accessor: "c3",
        disableSortBy: true,
      },
      {
        Header: "User ID",
        accessor: "c1",
        disableSortBy: true,
      },
      {
        Header: "Full Name",
        accessor: "c2",
        disableSortBy: true,
      },
    ], 
    //eslint-disable-next-line
    [sessions, t]
  );
  
  const {
    getTableProps,
   getTableBodyProps,
   headerGroups,
   rows,
   prepareRow,
  } = useTable({columns, data}, useSortBy);
  
  return (
    <>
      <Button
        size="sm"
        color="info"
        onClick={
          (e) => {
            e.preventDefault();
            history.push('/admin/settings');
          }
        }>
        {"Back"}
      </Button>

      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                    className={`session-table-header`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  );
};

export default WebsocketClients;
