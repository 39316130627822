import CONSTANTS from "../CONSTANTS";
//Backup
export const actionBackupAuthorization = () => ({
  type: CONSTANTS.AUTHORIZATION_BACKUP,
});
//Actions
// export const actionGetActions = () => ({
//   type: CONSTANTS.ACTIONS_GET,
// });
// export const actionGetAction = (id) => ({
//   type: CONSTANTS.ACTIONS_GET_ONE,
//   id,
// });
// export const actionSetActions = (tasks) => ({
//   type: CONSTANTS.ACTIONS_SET,
//   tasks,
// });
// export const actionSetAction = (task) => ({
//   type: CONSTANTS.ACTIONS_SET_ONE,
//   task,
// });
// export const actionSaveAction = (data) => ({
//   type: CONSTANTS.ACTIONS_SAVE,
//   data,
// });
// export const actionDeleteAction = (id) => ({
//   type: CONSTANTS.ACTIONS_DELETE,
//   id,
// });
// export const actionAvailableActions = () => ({
//   type: CONSTANTS.ACTIONS_AVAILABLE,
// });
// export const actionAllowedActions = () => ({
//   type: CONSTANTS.ACTIONS_ALLOWED,
// });
// export const actionSetAvailableActions = (tasks) => ({
//   type: CONSTANTS.ACTIONS_SET_AVAILABLE,
//   tasks,
// });
// export const actionSetAllowedActions = (tasks) => ({
//   type: CONSTANTS.ACTIONS_SET_ALLOWED,
//   tasks,
// });
//Modules
export const actionGetModules = () => ({
  type: CONSTANTS.MODULES_GET,
});
export const actionGetModule = (id) => ({
  type: CONSTANTS.MODULES_GET_ONE,
  id,
});
export const actionSetModules = (modules) => ({
  type: CONSTANTS.MODULES_SET,
  modules,
});
export const actionSetModule = (module) => ({
  type: CONSTANTS.MODULES_SET_ONE,
  module,
});
export const actionSaveModule = (data) => ({
  type: CONSTANTS.MODULES_SAVE,
  data,
});
export const actionDeleteModule = (id) => ({
  type: CONSTANTS.MODULES_DELETE,
  id,
});
//Actions
export const actionSaveModuleAction = (id, data) => ({
  type: CONSTANTS.MODULES_ACTIONS_SAVE,
  id,
  data,
});
export const actionDeleteModuleAction = (module_id, action_id) => ({
  type: CONSTANTS.MODULES_ACTIONS_DELETE,
  module_id,
  action_id,
});
//Functionalities
// export const actionGetFunctionalities = () => ({
//   type: CONSTANTS.FUNCTIONALITIES_GET,
// });
// export const actionGetFunctionality = (id) => ({
//   type: CONSTANTS.FUNCTIONALITIES_GET_ONE,
//   id,
// });
// export const actionSetFunctionalities = (functionalities) => ({
//   type: CONSTANTS.FUNCTIONALITIES_SET,
//   functionalities,
// });
// export const actionSetFunctionality = (functionality) => ({
//   type: CONSTANTS.FUNCTIONALITIES_SET_ONE,
//   functionality,
// });
// export const actionSaveFunctionality = (data) => ({
//   type: CONSTANTS.FUNCTIONALITIES_SAVE,
//   data,
// });
// export const actionDeleteFunctionality = (id) => ({
//   type: CONSTANTS.FUNCTIONALITIES_DELETE,
//   id,
// });
// export const actionAvailableFunctionalities = () => ({
//   type: CONSTANTS.FUNCTIONALITIES_AVAILABLE,
// });
// export const actionAllowedFunctionalities = () => ({
//   type: CONSTANTS.FUNCTIONALITIES_ALLOWED,
// });
// export const actionSetAvailableFunctionalities = (functionalities) => ({
//   type: CONSTANTS.FUNCTIONALITIES_SET_AVAILABLE,
//   functionalities,
// });
// export const actionSetAllowedFunctionalities = (functionalities) => ({
//   type: CONSTANTS.FUNCTIONALITIES_SET_ALLOWED,
//   functionalities,
// });

//Roles
export const actionGetRoles = () => ({
  type: CONSTANTS.ROLES_GET,
});
export const actionGetRole = (id) => ({
  type: CONSTANTS.ROLES_GET_ONE,
  id,
});
export const actionSetRoles = (roles) => ({
  type: CONSTANTS.ROLES_SET,
  roles,
});
export const actionSetRole = (role) => ({
  type: CONSTANTS.ROLES_SET_ONE,
  role,
});
export const actionSaveRole = (data) => ({
  type: CONSTANTS.ROLES_SAVE,
  data,
});
export const actionDeleteRole = (id) => ({
  type: CONSTANTS.ROLES_DELETE,
  id,
});
