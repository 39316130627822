/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create switch buttons
import PropTypes from "prop-types";
import { Doughnut } from "react-chartjs-2";

// reactstrap components
import {
    Card,
    CardBody,
    CardFooter,
    Row,
    Col,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

const WidgetCount = (props) => {
    const { amount, type, title, icon, days, total } = props;
    const lastDays = title.replace('X', days);
    const state = {
        dataDoughnut: {
            labels: [type, lastDays],
            datasets: [
                {
                    data: [total, amount],
                    backgroundColor: ["#2ca8ff", "#f96332",],
                    hoverBackgroundColor: [
                        "#5AD3D1",
                        "#fd7e14"
                    ]
                }
            ]
        }
    }

    return (
        <>
            <Col className="align-left" lg="4" sm="6">
                <Card className="card-stats">
                    <br />
                    <Doughnut data={state.dataDoughnut} options={{ responsive: true }} />
                </Card>
            </Col>
        </>
    );
}
WidgetCount.defaultProps = {
    amount: 0,
    total: 0,
    type: '',
    title: '',
    days: ''
};

WidgetCount.propTypes = {
    total: PropTypes.number,
    amount: PropTypes.number,
    type: PropTypes.string,
    title: PropTypes.string,
    days: PropTypes.string
};


export default WidgetCount;
