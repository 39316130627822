import CONSTANTS from "../CONSTANTS";

export const actionGetAllTranslations = (page = 0, limit = 5, search = null) => ({
  type: CONSTANTS.MANUAL_GET_ALL,
  page,
  limit,
  search
});

export const actionSetTranslations = (data) => ({
  type: CONSTANTS.MANUAL_SET_ALL,
  data
});

export const actionDeleteKey = (system, key) => ({
  type: CONSTANTS.MANUAL_DELETE_KEY,
  key,
  system
});

export const actionGetKeyData = (system, key) => ({
  type: CONSTANTS.MANUAL_GET_KEY,
  key,
  system
});

export const actionSetKeyData = (data) => ({
  type: CONSTANTS.MANUAL_SET_KEY,
  data
});

export const actionEditKeyData = (system, id, data) => ({
  type: CONSTANTS.MANUAL_EDIT_KEY,
  id,
  system,
  data
});

export const actionCreateKey = (data) => ({
  type: CONSTANTS.MANUAL_CREATE_KEY,
  data
});