import { takeEvery, put, call } from "redux-saga/effects";
import { toast } from "react-toastify";

//Redux Actions
import CONSTANTS from "../CONSTANTS";
import LogsService from "services/LogsService";
import { actionSetLogsList} from "../actions/logsActions";

function* sagaGetLogs({ page, limit, search }) {
    try {
      const result = yield call(LogsService.getLogs, page, limit, search);
      if (result.status >= 400) {
        throw new Error('Get logs failed');
      }
      yield put(actionSetLogsList(result.data.data));
    } catch (error) {
      toast.error(error.message || 'Get logs failed');
    }
}

export function* logsSaga() {
    yield takeEvery(CONSTANTS.LOGS_GET_LIST, sagaGetLogs);
}
  