import { takeEvery, put, select, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import CONSTANTS from '../CONSTANTS';
import historyRouter from 'historyRouter';
//Services
import PracticesService from '../../services/PracticesService';
//Actions
import { actionGetPendingPractices, actionSetAllPractices, actionSetPendingPractices, actionSetPractices } from '../actions/practicesActions';

/**
 * Get a practice
 * @param {json} practice
 */
function* sagaPractices({ page, limit, search }) {
  try {
    const result = yield call(PracticesService.getPractices,page,limit,search);
    if (result.status >= 400) {
      throw new Error(result.response);
    }
    if (result.status !== 404) {
      yield put(actionSetPractices(result.data.data));
    } else {
      yield put(actionSetPractices([]));
    }

  } catch (error) {
    // console.log(error);
    // toast.error(error.message || 'Unauthorized');
  }
}
function* sagaGetAllPractices({days}) {
  try {
    const result = yield call(PracticesService.getAllPractices,days);
    if (result.status >= 400) {
      throw new Error('Get System Practices failed');
    }
    yield put(actionSetAllPractices(result.data));
  } catch (error) {
    toast.error(error.message || 'Get invitations failed');
  }
}

function* sagaGetPendingPractices() {
  try {
    const result = yield call(PracticesService.getPendingPractices);
    if (result.status >= 400) {
      throw new Error(result.response);
    }
    if (result.status !== 404) {
      yield put(actionSetPendingPractices(result.data));
    } else {
      yield put(actionSetPendingPractices([]));
    }

  } catch (error) {
    // console.log(error);
    // toast.error(error.message || 'Unauthorized');
  }
}

function* sagaResolveModerationState({ practiceId, status }) {
  try {
    const result = yield call(PracticesService.resolveModeration, practiceId, status);
    if (result.status >= 400) {
      throw new Error(result.response);
    }
    
    toast.success(`Practice ${!status ? "denied" : "approved"} successfully`);
    yield put(actionGetPendingPractices());
  } catch (error) {
    // console.log(error);
    // toast.error(error.message || 'Unauthorized');
  }
}

export function* practicesSaga() {
  console.log('*Main Practices Saga');
  yield takeEvery(CONSTANTS.PRACTICES_GET_LIST, sagaPractices);
  yield takeEvery(CONSTANTS.PRACTICES_GET_ALL, sagaGetAllPractices);
  yield takeEvery(CONSTANTS.PRACTICES_GET_MODLIST, sagaGetPendingPractices);
  yield takeEvery(CONSTANTS.PRACTICES_SOLVE_MODERATION, sagaResolveModerationState);
}
