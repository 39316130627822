import CONSTANTS from "../CONSTANTS";

export const actionGetSystemRoutes = (host) => ({
  type: CONSTANTS.SYSTEM_GET_ROUTES,
  host,
});

export const actionSetSystemRoutes = (routes) => ({
  type: CONSTANTS.SYSTEM_SET_ROUTES,
  routes,
});
//Count doctors
export const actionGetAllDoctorsSystem = (days = 1) => ({
  type: CONSTANTS.SYSTEM_GET_DOCTORS_ALL,
  days
});
export const actionSetAllDoctorsSystem = (systemUsers) => ({
  type: CONSTANTS.SYSTEM_SET_DOCTORS_ALL,
  systemUsers,
});

//Count patients
export const actionGetAllPatientsSystem = (days = 1) => ({
  type: CONSTANTS.SYSTEM_GET_PATIENTS_ALL,
  days
});
export const actionSetAllPatientsSystem = (systemUsers) => ({
  type: CONSTANTS.SYSTEM_SET_PATIENTS_ALL,
  systemUsers,
});

//Count HL7 metrics
export const actionGetAllHl7Consent = () => ({
  type: CONSTANTS.SYSTEM_GET_HL7_CONSENT,
});
export const actionSetAllHl7Consent = (HL7metrics) => ({
  type: CONSTANTS.SYSTEM_SET_HL7_CONSENT,
  HL7metrics,
});
