import CONSTANTS from '../CONSTANTS';

const initialState = {
  logs: [],
};

const logsReducer = (state = initialState, action) => { 
  switch (action.type) {
    case CONSTANTS.LOGS_SET_LIST:
        return { ...state, logs: action.logs};   
    default:
      return state;
  }
};
export default logsReducer;