import AxiosService from "plugins/TS-lib-utils-public/services/AxiosService";

class TranslationService extends AxiosService {
    constructor() {
    super("management");
    }

    getAll = async (page = 0, limit = 10, search = null) => {
        try {
            let result;

            if(search){
                result = await this.get(`/list?page=${page}&limit=${limit}&search=${search}`, {
                    headers: {
                        Authorization: `Bearer ${this.getToken()}`,
                    }
                });
            }else{
                result = await this.get(`/list?page=${page}&limit=${limit}`, {
                    headers: {
                        Authorization: `Bearer ${this.getToken()}`,
                    }
                });
            }

            return result;

        } catch (error) {
            return {
                status: error.response.status,
                statusText: error.response.statusText,
            };
        }
    };

    getLanguages = async (system, key) => {
        try {
            let result = await this.get(`/keys/list`, {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
                params: {
                    system,
                    key
                }
            });

            return result;
        } catch (error) {
            return {
                status: error.response.status,
                statusText: error.response.statusText,
            };
        }
    };

    deleteKey = async (system, key) => {
        try {
            let result = await this.delete(`/`, {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
                params: {
                    system,
                    key
                }
            });

            return result;
        } catch (error) {
            return {
                status: error.response.status,
                statusText: error.response.statusText,
            };
        }
    };

    editKey = async (system, id, keyInfo) => {
        try {
            let result = await this.put(`/`, keyInfo, {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
                params: {
                    system,
                    key: id
                }
            });

            return result;
        } catch (error) {
            return {
            status: error.response.status,
            statusText: error.response.statusText,
            };
        }
    };

    getKeyData = async (system, key) => {
        try {
            let result = await this.get(`/`, {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
                params: {
                    system,
                    key
                }
            });
            
            return result;
        } catch (error) {
            return {
            status: error.response.status,
            statusText: error.response.statusText,
            };
        }
    };

    createKey = async (data) => {
        try {
            let result = await this.post(`/`, data, {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                }
            });
            
            return result;
        } catch (error) {
            return {
            status: error.response.status,
            statusText: error.response.statusText,
            };
        }
    };

    createLanguage = async (system, key, data) => {
        try {
            let result = await this.post(`/keys`, data, {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
                params: {
                    system,
                    key
                }
            });
            
            return result;
        } catch (error) {
            return {
            status: error.response.status,
            statusText: error.response.statusText,
            };
        }
    };

    deleteLanguage = async (system, key, lang) => {
        try {
            let result = await this.delete(`/keys`, {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
                params: {
                    system,
                    key,
                    lang
                }
            });
            
            return result;
        } catch (error) {
            return {
            status: error.response.status,
            statusText: error.response.statusText,
            };
        }
    };

    updateLanguage = async (system, key, lang, data) => {
        try {
            let result = await this.put(`/keys`, data, {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
                params: {
                    system,
                    key,
                    lang
                }
            });
            
            return result;
        } catch (error) {
            return {
            status: error.response.status,
            statusText: error.response.statusText,
            };
        }
    };

}

export default new TranslationService();