import CONSTANTS from '../CONSTANTS';

const initialState = {
    studies: [],
    studiesby: [],
};

const billingReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.BILLING_SET_ALL:
      return { ...state, studies: action.studies };    
    case CONSTANTS.BILLING_SET_ALL_BY_PRACTICE:
      return { ...state, studiesby: action.studies };     
    default:
      return state;
  }
};

export default billingReducer;

