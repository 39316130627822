//Page for edition user data

import React from 'react';
// reactstrap components
import { Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import { useTranslate } from 'react-translate';
import WebsocketList from 'components/Users/WebsocketList';

const WebsocketClientsViewer = () => {
  const t = useTranslate('settings');
  return (
    <>
      {/*<PanelHeader size="sm" /> */}
      <Card className="profile">
        <CardBody>
          <Card className="mb-0">
            <CardHeader>
              <CardTitle className="d-flex align-content-center">
                <div className="general-card-title text-primary">
                  {"Websocket clients"}
                </div>
              </CardTitle>
            </CardHeader>

            <CardBody>
              <WebsocketList />
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </>
  );
};

export default WebsocketClientsViewer;
