import React from 'react';
import PropTypes from "prop-types";
import { Table } from 'reactstrap';
import { translate } from "react-translate";
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';

const TablePatientUsers = (props) => {
    const { data, loading } = props;
    const { t } = props;

    return (
        <>
            {loading ?
                (<LoadingLocal />)
                :
                (<div className="table-data">
                    <Table>
                        <thead>
                            <tr>
                                <th> {t("firstName-user")}</th>
                                <th> {t("lastName-user")}</th>
                                <th> {t("email-user")}</th>
                                <th> {t("type-user")}</th>
                                <th> {"Verified"} </th>
                            </tr>
                        </thead>

                        {data.length > 0 && (data.map((p, index) => {
                            return (
                                <tbody key={index}>
                                    <tr>
                                        <th>{p.firstName}</th>
                                        <th>{p.lastName}</th>
                                        <th>{p.email}</th>
                                        <th>{p.membership}</th>
                                        <th>{p.is_ready ? "Yes" : "No"}</th>
                                    </tr>
                                </tbody>
                            )
                        })
                        )}
                    </Table>
                </div>)}

        </>
    );
};
TablePatientUsers.defaultProps = {
    data: [],
    loading: true
};

TablePatientUsers.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool
};

export default translate("userList-table")(TablePatientUsers);