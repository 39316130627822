/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useCallback } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
// react plugin for creating notifications
import { ToastContainer, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.jsx';
import Footer from 'components/Footer/Footer.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';
// import useSessionTimer from "../plugins/TS-lib-utils-public/hooks/useSessionTimer.jsx";

import routes from 'routes.js';
import { useSelector, useDispatch } from 'react-redux';
import { TranslatorProvider } from 'react-translate';
import momentjsI18n from 'helpers/momentjsI18n';
import { actionSetLang, actionSetDateFormat } from 'store/actions/usersActions';
import { actionGetUser } from 'store/actions/usersActions';

var ps;

const Admin = (props) => {
  const dispatch = useDispatch();
  const { sweetAlert } = useSelector((state) => state.commonReducer);
  const { language } = useSelector((state) => state.userReducer);
  const user = useSelector((state) => state.userReducer);

  // useSessionTimer(user);

  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      let fullPage = document.getElementById('full-page');
      document.documentElement.className += ' perfect-scrollbar-on';
      document.documentElement.classList.remove('perfect-scrollbar-off');
      // ps = new PerfectScrollbar(mainPanel.current);
      ps = new PerfectScrollbar(fullPage);
    }

    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.documentElement.className += ' perfect-scrollbar-off';
        document.documentElement.classList.remove('perfect-scrollbar-on');
      }
    };
  }, []);

  useEffect(() => {
    dispatch(actionGetUser());
    if (user._id) {
      momentjsI18n.setFormat(user.dateFormat);
      momentjsI18n.setLang(user.language);
      dispatch(actionSetLang(user.language));
      dispatch(actionSetDateFormat(user.dateFormat));
    }
    return () => null;
    // eslint-disable-next-line
  }, [dispatch]);

  const minimizeSidebar = () => {
    // eslint-disable-next-line
    let message = 'Sidebar mini ';
    if (document.body.classList.contains('sidebar-mini')) {
      // setSidebarMini(false);
      message += 'deactivated...';
    } else {
      // setSidebarMini(true);
      message += 'activated...';
    }
    document.body.classList.toggle('sidebar-mini');
  };

  const getRoutes = (routes) => {
    if (!user._id) {
      return <Redirect to='/auth' />;
    } else if (user.auth_2fa && user.auth_2fa === 'pending') {
      return <Redirect to='/auth/lock-screen-page' />;
    }
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    });
  };

  const getActiveRoute = useCallback((routes) => {
    let activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  }, []);

  return (
    <TranslatorProvider
      translations={require(`assets/i18n/${language || 'en'}.json`)}
    >
      {/* SweetAler Global */}
      {sweetAlert}
      <ToastContainer
        position='top-center'
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Flip}
      />
      <div className='wrapper animated fadeIn'>
        <Sidebar
          {...props}
          routes={routes}
          minimizeSidebar={minimizeSidebar}
          backgroundColor='blue'
        />
        <AdminNavbar {...props} brandText={getActiveRoute(routes)} />
        <div
          className='main-panel admin_layout'
          id='full-page'
          // ref={mainPanel}
        >
          <Switch>
            {getRoutes(routes)}
            <Redirect from='/admin' to='/admin/roles-system' />
            {/* <Redirect from="/admin" to="/admin/welcome" /> */}
          </Switch>
          {
            // we don't want the Footer to be rendered on full screen maps page
            window.location.href.indexOf('full-screen-maps') !== -1 ? null : (
              <Footer fluid />
            )
          }
        </div>

        {/*
        <FixedPlugin
          handleMiniClick={minimizeSidebar}
          sidebarMini={sidebarMini}
          bgColor={backgroundColor}
          handleColorClick={handleColorClick}
        />
        */}
      </div>
    </TranslatorProvider>
  );
};

export default Admin;
