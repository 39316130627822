//Get reducer from store
export const getUser = (state) => state.userReducer;
export const getModule = (state) => state.modulesReducer.module;
export const getActions = (state) => state.actionsReducer.actions;
export const getRole = (state) => state.rolesReducer.role;
export const getTranslations = (state) => state.manualReducer;
export const getPractices = (state) => state.practicesReducer;
export const getUsersDoctorReducer = (state) => state.usersDoctorReducer;
export const getSystemReducer= (state) => state.systemReducer;
export const getWebsocketClientList = (state) => state.websocketReducer.clientList;
export const getLogs = (state) => state.logsReducer;
export const getAnalysis = (state) => state.analysisReducer;