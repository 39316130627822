import React, { useEffect, useState,useRef } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Label,
  FormGroup,
  Form,
  Input,
  Button,
  Row,
  Container,
  Col
} from 'reactstrap';
import TagsInput from 'react-tagsinput';
import { useDispatch } from "react-redux";
import {
  actionEditKeyData,
  actionCreateKey
} from 'store/actions/manualActions';
import { useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import ReactQuill from 'react-quill';
import helpRoutesDoctors from 'plugins/TS-lib-utils-public/data/helpInfoWebDoctors.json';
import helpRoutesPatients from 'plugins/TS-lib-utils-public/data/helpInfoWebPatients.json';
import 'react-quill/dist/quill.snow.css';

const UserManualForm = () => {
  const dispatch = useDispatch();

  //States
  const [tags, setTags] = useState([]);
  const [Languages, setLanguages] = useState([]);
  const [LanguagesInit, setLanguagesInit] = useState([]);
  const [helpRoutes, setHelpRoutes] = useState(keyData?.system === "web-doctors" ? helpRoutesDoctors : helpRoutesPatients);
  const keyData = useSelector(state => state.manualReducer.data);
  const system = keyData?.system;
  const id = keyData?.key;

  //Form 
  const defaultValues = keyData ? keyData : 
  {
    title: "",
    tags: [],
    text: []
  };
  
  const { register, handleSubmit, reset } = useForm({
    defaultValues: defaultValues,
  });

  const history = useHistory();

  //Initialization
  useEffect(() => {
    reset(defaultValues);

    if (defaultValues !== null && Object.entries(defaultValues).length) {
      const contrlTags = defaultValues.tags !=null ? defaultValues.tags :[];
      setTags(contrlTags);
      setLanguages(defaultValues.text);
      setLanguagesInit(defaultValues.text);
    }

    // eslint-disable-next-line
  }, [reset, defaultValues]);


  //Function to add new fields for Languages
  const addLanguage = (e) => {
    e.preventDefault();
    setLanguagesInit(Languages);
    let temp = Languages;
    const dummyLang = ["es", "en", "ru", "de"];
    temp.push({
      _id: Date.now(),
      language: dummyLang[Languages.length],
      text: "",
    });
    setLanguages([...temp]);
  };

  //Function to delete Language fields
  const deleteLanguage = (e, index) => {
    e.preventDefault();
    setLanguages([...Languages.filter((text, id) => {
      return id !== index;
    })]);
  };

  //Function to update a language text
  const updateLanguageText = (index, textEditor) => {
    setLanguages([...Languages.map((text, i) => {
      if (index === i) {
        return { ...text, text: textEditor};
      }
      return { ...text };
    })]);
  };


  //Function to update a language alias
  const updateLanguageAlias = (e, index) => {
    setLanguages([...Languages.map((text, i) => {
      if (index === i) {
        return { ...text, language: e.target.value };
      }
      return { ...text };
    })]);
  };

  const languageContainsDuplicate = (lang) => {
    return Languages.filter(text => { return text.language === lang }).length > 1;
  };

  const onSubmit = (data) => {

    const duplicates = Languages.filter(text => {
      return languageContainsDuplicate(text.language);
    });

    if ( !duplicates.length ) {
      const finalData = { ...data, tags: tags.join(","), text: Languages };

      if (!Object.keys(keyData).length) {
        dispatch(actionCreateKey(finalData));
      } else {
        dispatch(actionEditKeyData(system, id, finalData));
      }
      
      history.push('/admin/user-manual');
    }
  };

  return (
    <div className="content user-manual-edit">

      <Card>
        <CardHeader>{keyData && Object.keys(keyData).length ? `User Manual Edition Form for ${id}` : `User Manual Creation Form` }</CardHeader>
        <CardBody>
          <Form id="edit-key-form" onSubmit={handleSubmit(onSubmit)}>
            <Label>Key</Label>
            <FormGroup>
              <Input
                type="select"
                name="key"
                required
                innerRef={register}
              >
                {helpRoutes.routes && helpRoutes.routes.sort((l,r) => l._id > r._id).map((v) => (
                <option value={v.key} key={v.key}>
                  {v._id}
                </option>
                ))}

              </Input>
            </FormGroup>

            <Label>Title</Label>
            <FormGroup>
              <Input
                type="text"
                name="title"
                required
                defaultValue={keyData.title}
                innerRef={register}
              />
            </FormGroup>

            <Label>Tags</Label>
            <br />

            <FormGroup>
              <TagsInput
                inputProps={{
                  name: 'tags',
                  placeholder: '',
                }}
                value={tags}
                tagProps={{ className: 'react-tagsinput-tag' }}
                onChange={setTags}
              />
              {!tags.length && ("Tag field cannot be empty.")}
            </FormGroup>

            <Label>System</Label>
            <FormGroup>
              <Input
                type="select"
                name="system"
                required
                innerRef={register}
                onChange={(e) => {
                  if (e.target.value === "web-doctors") {
                    setHelpRoutes(helpRoutesDoctors);
                  } else {
                    setHelpRoutes(helpRoutesPatients);
                  }
                }}
              > 
                <option value="web-doctors">
                  web-doctors
                </option>
                <option value="web-patients">
                  web-patients
                </option>
              </Input>
            </FormGroup>

            <Container fluid={true}>
              {Languages.map((text, index) => {

                return (
                  <Row key={`${text._id}`}>
                    <Col sm={3}>
                      <Label>Language</Label>
                      <FormGroup>
                        <Input type="text" required defaultValue={text.language} onChange={(e) => updateLanguageAlias(e, index)} />
                      </FormGroup>

                      {languageContainsDuplicate(text.language) && ("Languages cannot contain the same identificator.")}
                    </Col>

                    <Col>
                      <Label>Text</Label>
                      <FormGroup>
                        <ReactQuill defaultValue={LanguagesInit[index].text} onChange={(text) => updateLanguageText(index,text)}/>
                      </FormGroup>
                    </Col>

                    <Col sm={2}>
                      <Button size="sm" color="danger" onClick={(e) => deleteLanguage(e, index)}>
                        Delete language
                      </Button>
                    </Col>
                  </Row>
                );
              })}
            </Container>

            {Languages.length < 4 && (
              <Button size="sm" color="info" onClick={(e) => addLanguage(e)}>
                Add language
              </Button>
            )}

            <br />
          </Form>

          <Button form="edit-key-form" size="sm" color="info" type="submit">
            {keyData && Object.keys(keyData).length ? "Update" : "Create"}
          </Button>

          <Button
            form="edit-key-form"
            size="sm"
            color="danger"
            type="reset"
            onClick={(e) => {
              e.preventDefault();
              history.goBack();
            }}
          >
            Cancel
          </Button>
        </CardBody>
      </Card>
    </div>
  );
};

export default UserManualForm;
