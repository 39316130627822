export default {
  de: {
    abbr: "DEU",
    name: "Deutsch",
    img:  "assets/img/de.png",
    file: "assets/i18n/de.json",
  },
  ru: {
    abbr: "РYC",
    name: "Русский",
    img:  "assets/img/ru.png",
    file: "assets/i18n/ru.json",
  },
  en: {
    abbr: "ENG",
    name: "English",
    img:  "assets/img/en.png",
    file: "assets/i18n/en.json",
  },
  es: {
    abbr: "ESP",
    name: "Español",
    img:  "assets/img/es.png",
    file: "assets/i18n/es.json",
  },
};
