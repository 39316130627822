import React from "react";
import PropTypes from "prop-types";
import "./css/loadingGlobal.css";
const LoadingGlobal = ({ loading }) => {
  if (!loading) {
    return null;
  }
  return (
    <div className="loading">
      <div className="sk-chase">
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
      </div>
    </div>
  );
};

LoadingGlobal.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default LoadingGlobal;
