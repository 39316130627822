/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create switch buttons
import PropTypes from "prop-types";

// reactstrap components
import {
    Card,
    CardBody,
    CardFooter,
    Row,
    Col,
} from "reactstrap";



const WidgetCount = (props) => {
    const { amount, type, title, icon } = props;

    return (
        <>

            <Col className="aligWidgetCount-left" lg="3" sm="6" >
                <Card className="card-stats">
                    <CardBody>
                        <div className="statistics statistics-horizontal">
                            <div className="info info-horizontal">
                                <Row>
                                    <Col xs="5">
                                        <div className="icon icon-primary icon-circle">
                                            <i className={`now-ui-icons ${icon}`}/>
                                        </div>
                                    </Col>
                                    <Col className="text-right" xs="7">
                                        <h3 className="info-title">{amount}</h3>
                                        <h6 className="stats-title">{type}</h6>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </CardBody>
                    
                    <CardFooter>
                        <div className="stats">
                            {/* <i className="now-ui-icons arrows-1_refresh-69" />
                            {title} */}
                        </div>
                    </CardFooter>
                </Card>
            </Col>
        </>
    );
}
WidgetCount.defaultProps = {
    amount: 0,
    type: '',
    title: '',
    icon:''
};

WidgetCount.propTypes = {
    amount: PropTypes.number,
    type: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.string
};


export default WidgetCount;
