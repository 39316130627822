import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { actionGetRoles } from "store/actions/authorizationActions";
import { actionGetModules } from "store/actions/authorizationActions";
import { actionSetRole } from "store/actions/authorizationActions";
import ModalAuthButton from "./ModalAuthButton";
import ModalActions from "./ModalActions";
import SweetAlert from "react-bootstrap-sweetalert";
import { actionDeleteRole } from "store/actions/authorizationActions";
import { actionSaveRole } from "store/actions/authorizationActions";

const RolesSection = () => {
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(null);
  const [modal, setModal] = useState(false);
  const { roles, role } = useSelector((state) => state.rolesReducer);
  const modules = useSelector((state) => state.modulesReducer.modules);

  const toggle = () => setModal(!modal);

  const updateRole = async (e) => {
    e.preventDefault();
    let auxModules = {};
    const actionsChecked = document.querySelectorAll(".action-item:checked");
    const modulesAux = [...modules];

    if (actionsChecked?.length) {
      actionsChecked.forEach((action) => {
        const moduleId = action.getAttribute("data-module");
        const actionItem = action.value;
        if (!auxModules.hasOwnProperty(moduleId)) {
          auxModules[moduleId] = modulesAux.find((m) => m._id === moduleId);
          auxModules[moduleId].actions = [];
        }
        auxModules[moduleId].actions.push(JSON.parse(actionItem));
      });
    }
    let roleUpdated = { ...role };
    roleUpdated.modules = Object.keys(auxModules).map((m) => auxModules[m]);
    dispatch(actionSaveRole(roleUpdated));
  };

  const handleRoles = (e) => {
    let roleId = e.target.value;
    if (roleId) {
      const role = roles.find((role) => role._id === roleId);
      if (role) {
        return dispatch(actionSetRole(role));
      }
      return dispatch(actionSetRole({}));
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setShowAlert(
      <SweetAlert
        warning
        title="Are you sure?"
        onConfirm={() => {
          dispatch(actionDeleteRole(role?._id));
          setShowAlert(null);
        }}
        onCancel={() => setShowAlert(null)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to recover this action!
      </SweetAlert>
    );
  };

  useEffect(() => {
    dispatch(actionSetRole({}));
    dispatch(actionGetRoles());
    dispatch(actionGetModules());
  }, [dispatch]);

  return (
    <>
      {showAlert}
      <ModalActions
        modal={modal}
        item={role}
        toggle={toggle}
        type="role"
        title="Role edit"
      />
      <Row>
        <Col sm="12" md="6">
          <div className="d-flex w-75">
            <FormGroup className="w-100">
              <Label for="rolesSelect">Select a Role</Label>
              <Input
                type="select"
                name="_id"
                id="rolesSelect"
                onChange={handleRoles}
                defaultValue={role?._id}
              >
                <option>None</option>
                {roles &&
                  roles.map((role) => (
                    <option value={role._id} key={role._id}>
                      {role.name}
                    </option>
                  ))}
              </Input>
            </FormGroup>
            {Object.keys(role).length > 0 && (
              <div className="btn-action-item">
                <i
                  className="icon-pencil btn-action"
                  onClick={() => {
                    toggle();
                  }}
                ></i>{" "}
                <i className="icon-trash btn-action" onClick={handleDelete}></i>
              </div>
            )}
          </div>
        </Col>
        <Col sm="12" md="6">
          <div className="d-flex justify-content-end mt-2">
            <ModalAuthButton
              buttonLabel="New role"
              buttonSize="sm"
              type="role"
              title="Roles Management"
            />
            <ModalAuthButton
              buttonLabel="New Module"
              buttonSize="sm"
              type="module"
              title="Module Management"
            />
            {Object.keys(role).length > 0 && (
              <>
                <Button color="primary" size="sm" onClick={updateRole}>
                  Save
                </Button>
              </>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default RolesSection;
