import React from 'react';
import history from './historyRouter';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import AdminLayout from 'layouts/Admin.jsx';
import AuthLayout from 'layouts/Auth.jsx';

const App = React.memo(() => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          path='/admin'
          render={(props) => {
            return <AdminLayout {...props} />;
          }}
        />
        <Route
          path='/auth'
          render={(props) => {
            return <AuthLayout {...props} />;
          }}
        />
        <Redirect from='/' to='/auth/login-page' />
      </Switch>
    </Router>
  );
});

export default App;
