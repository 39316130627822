import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import { translate } from "react-translate";

import { actionGetUsersDoctorList } from 'store/actions/usersActions';
import TableDoctorUsers from './TableDoctorUsers';
import Paginator from '../../components/Common/Paginator';
import Search from '../../components/Common/Search'

import Loading from "plugins/TS-lib-utils-public/components/Loading/LoadingGlobal";


const ListDoctorUsers = (props) => {

    const dispatch = useDispatch();
    const { t } = props;
    const { users } = useSelector(state => state.userDoctorsReducer.doctors);
    const { pagination } = useSelector(state => state.userDoctorsReducer.doctors);
    const [loading, setloading] = useState(false);
    const [loadingLocal, setloadingLocal] = useState(false);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [totalPage, setTotalPage] = useState(0);
    const [filter, setFilter] = useState(null);


    useEffect(() => {

        dispatch(actionGetUsersDoctorList(page, limit));

    }, []);

    useEffect(() => {

        if (users && pagination) {
            setloading(false);
            setloadingLocal(false);
            setPage(pagination._page);
            setLimit(pagination._limit);
            setTotalPage(pagination._total_pages);
        }

    }, [dispatch, users]);

    //method called by paginator and search
    function changeDataSearch(page, filter) {
        //if the search type "" refresh all data
        setloadingLocal(true)
        if (filter == null) {
            setFilter(null);
            dispatch(actionGetUsersDoctorList(page, limit));
        } else {
            //we save search input text, therefore we will only paginate the search results
            setFilter(filter);
            dispatch(actionGetUsersDoctorList(page, limit, filter));
        }
    }

    return (
        <div className='content user-manual'>           
                <CardBody>
                    <div>
                        <Loading loading={loading} />
                        <Search placeholder={"search-text-userList"} callback={changeDataSearch} />
                        <TableDoctorUsers data={users} loading={loadingLocal} />
                        <Paginator totalPages={totalPage} currentPage={page} pageSize={limit} filter={filter} callback={changeDataSearch} />
                    </div>
                </CardBody>
        </div>
    );
};
export default translate("userList")(ListDoctorUsers);