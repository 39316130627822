import CONSTANTS from '../CONSTANTS';

const initialState = {
  practices: [],
  totalPractices:[],
  pendingPractices:[]
};

const practicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.PRACTICES_SET_LIST:
      return { ...state, practices: action.data };    
    case CONSTANTS.PRACTICES_SET_MODLIST:
      return { ...state, pendingPractices: action.practices };   
    case CONSTANTS.PRACTICES_SET_ALL:
      return { ...state, totalPractices: action.totalPractices };   
    default:
      return state;
  }
};

export default practicesReducer;
