import CONSTANTS from "../CONSTANTS";

const loadingReducer = (state = false, action) => {
  switch (action.type) {
    case CONSTANTS.LOADING:
      return action.loading;
    default:
      return state;
  }
};

export default loadingReducer;
