import CONSTANTS from "../CONSTANTS";

export const actionGetAnomaliesList = (page = 0, limit = 5 , search=null) => ({
  type: CONSTANTS.ANOMALIES_GET_LIST,
  page,
  limit,
  search
});

export const actionSetAnomaliesList = (anomalies) => ({
  type: CONSTANTS.ANOMALIES_SET_LIST,
  anomalies,
});

export const actionSetAnomalyEdit = (anomaly) => ({
  type: CONSTANTS.ANOMALIES_SET_EDIT,
  anomaly,
});

export const actionAnomalySubmitForm = (data) => ({
  type: CONSTANTS.ANOMALIES_SUBMIT_FORM,
  data
});

export const actionGetAnomalyEdit = (anomalyId) => ({
  type: CONSTANTS.ANOMALIES_GET_EDIT,
  anomalyId
});

export const actionResendStudy = (anomalyId, hl7Id) => ({
  type: CONSTANTS.ANOMALIES_RESEND,
  anomalyId,
  hl7Id
});
