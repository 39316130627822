import { eventChannel } from 'redux-saga';
import { fork, take, call, put, select, cancel, takeEvery } from 'redux-saga/effects';
//Service
import WsService from 'plugins/TS-lib-utils-public/services/WsService';
//Selectors
import CONSTANTS from '../CONSTANTS';
import { actionSetClientList } from '../actions/websocketActions';

function* sagaWebsocketClients() {
  try {
    //Send recovery password to email
    const result = yield call(WsService.getConnections, "all", "all");
    
    if (result.status >= 400) {
      throw new Error("Request failed");
    }

    yield put(actionSetClientList(result.data));
  } catch (error) {
    // toast.error('An error ocurred while processing your request.');
  }
}

export function* wsSaga() {
  console.log('*Main WS Saga');
  yield takeEvery(CONSTANTS.WS_GET_CLIENTLIST, sagaWebsocketClients);
}
