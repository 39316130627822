import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Label,
  FormGroup,
  Form,
  Input,
  Button,
  Row,
  Container,
  Col
} from 'reactstrap';
import TagsInput from 'react-tagsinput';
import { useDispatch } from "react-redux";
import {
  actionEditKeyData,
  actionCreateKey
} from 'store/actions/manualActions';
import { useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { actionSubmitForm } from 'store/actions/usersActions';

const DoctorUsersForm = () => {
  const dispatch = useDispatch();

  //States
  const userData = useSelector(state => state.userDoctorsReducer.editData);
  
  const { register, handleSubmit } = useForm({
    defaultValues: {
      membership: userData.membership
    }
  });
  const history = useHistory();

  const onSubmit = (data) => {
    dispatch(actionSubmitForm({...userData, ...data}));
    history.goBack();
  };

  return (
    <div className="content user-manual-edit">

      <Card>
        <CardHeader>{`${userData?.pronoun} ${userData?.firstName} ${userData?.lastName} membership edition form`}</CardHeader>
        <CardBody>
          <Form id="user-edit-form" onSubmit={handleSubmit(onSubmit)}>

          <Label>Membership</Label>
            <FormGroup>
              <Input
                type="select"
                name="membership"
                required
                innerRef={register}
              > 
                <option value="full">Full</option>
                <option value="basic">Basic</option>
              </Input>
            </FormGroup>
          </Form>

          <Button form="user-edit-form" size="sm" color="info" type="submit">
            Update
          </Button>

          <Button
            form="user-edit-form"
            size="sm"
            color="danger"
            type="reset"
            onClick={(e) => {
              e.preventDefault();
              history.goBack();
            }}
          >
            Cancel
          </Button>
        </CardBody>
      </Card>
    </div>
  );
};

export default DoctorUsersForm;
