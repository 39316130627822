import CONSTANTS from "../CONSTANTS";

//Count billings
export const actionGetBilling= (days = 1) => ({
  type: CONSTANTS.BILLING_GET_ALL,
  days
});

export const actionSetBilling= (studies) => ({
  type: CONSTANTS.BILLING_SET_ALL,
  studies
});

//Count billings by practice
export const actionGetBillingByPractice = (days) => ({
  type: CONSTANTS.BILLING_GET_ALL_BY_PRACTICE,
  days,
});

//Count billings by practice
export const actionSetBillingByPractice = (studies) => ({
  type: CONSTANTS.BILLING_SET_ALL_BY_PRACTICE,
  studies,
});