import CONSTANTS from "../CONSTANTS";

const commonReducer = (
  state = {
    sweetAlert: null,
    collapse: "WaitingRoom", //string with name component collapsable
  },
  action
) => {
  switch (action.type) {
    case CONSTANTS.SWEET_ALERT:
      return { ...state, sweetAlert: action.sweetAlert };
    case CONSTANTS.COLLAPSE:
      return { ...state, collapse: action.collapse };
    default:
      return state;
  }
};

export default commonReducer;
