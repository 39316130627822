import AutorizationService from "./AutorizationService";
class ModulesService extends AutorizationService {
  constructor() {
    super("modules");
  }

  /**
   * Add action
   */
  addAction = async (id, data) => {
    const response = await this.post(`/admin/modules/${id}/actions`, data, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    return response;
  };
  /**
   * Edit action
   */
  updateAction = async (id, data) => {
    const response = await this.put(`/admin/modules/${id}/actions`, data, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    return response;
  };
  /**
   * Delete action
   */
  deleteAction = async (module_id, action_id) => {
    const response = await this.delete(
      `/admin/modules/${module_id}/actions/${action_id}`,
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      }
    );
    return response;
  };
}

export default new ModulesService();
