import React, { Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Button } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import { actionSweetAlert } from "store/actions/commonActions";
import { actionBackupAuthorization } from "store/actions/authorizationActions";
import RolesSection from "../../components/Authorization/RolesSection";
import LoadingLocal from "plugins/TS-lib-utils-public/components/Loading/LoadingLocal";
import { actionSaveRole } from "store/actions/authorizationActions";
const RolesFormContainer = React.lazy(() =>
  import("../../components/Authorization/RolesFormContainer")
);

const RolesSystem = () => {
  const dispatch = useDispatch();

  const backupConfirm = () => {
    dispatch(
      actionSweetAlert(
        <SweetAlert
          warning
          title="Are you sure?"
          onConfirm={() => {
            dispatch(actionBackupAuthorization());
            dispatch(actionSweetAlert(null));
          }}
          onCancel={() => dispatch(actionSweetAlert(null))}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, backup it!"
          cancelBtnText="Cancel"
          showCancel
        >
          This action will overwrite the backup files!
        </SweetAlert>
      )
    );
  };

  return (
    <div className="content dashboard">
      <Card>
        <CardHeader className="d-flex justify-content-between align-content-center">
          <h5 className="title">Roles System</h5>
          <Button
            color="info"
            size="sm"
            style={{ marginTop: "0px" }}
            onClick={backupConfirm}
          >
            Backup <i className="icon-password"></i>
          </Button>
        </CardHeader>
        <CardBody>
          <RolesSection />
          <Suspense fallback={<LoadingLocal />}>
            <RolesFormContainer />
          </Suspense>
        </CardBody>
      </Card>
    </div>
  );
};

export default RolesSystem;
