import CONSTANTS from '../CONSTANTS';

const initialState = {
  analysis: [],
};

const analysisReducer = (state = initialState, action) => { 
  switch (action.type) {
    case CONSTANTS.ANALYSIS_SET_LIST:
        return { ...state, analysis: action.analysis};   
    default:
      return state;
  }
};
export default analysisReducer;