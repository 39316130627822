/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from 'views/Dashboard/Dashboard.jsx';
import RegularForms from 'views/Forms/RegularForms.jsx';
import ExtendedForms from 'views/Forms/ExtendedForms.jsx';
import ValidationForms from 'views/Forms/ValidationForms.jsx';
import Wizard from 'views/Forms/Wizard/Wizard.jsx';
import Charts from 'views/Charts/Charts.jsx';

import RegisterPage from 'views/Users/RegisterPage';
import LoginPage from 'views/Users/LoginPage';
import LockScreenPage from 'views/Users/LockScreenPage';
// import Authorization from 'views/Authorization/Authorization.jsx';
import RolesSystem from 'views/Authorization/RolesSystem.jsx';
import UserManual from 'views/Manual/UserManual';
import Practices from 'views/Practice/Practices';
import UserManualForm from 'components/UserManual/UserManualForm';
import DoctorUsers from 'views/DoctorUsers/DoctorUsers';
import PatientUsers from 'views/PatientUsers/PatientUsers';
import DoctorUsersEdit from 'views/DoctorUsers/DoctorUsersEdit';
import WebsocketClientsViewer from 'views/Users/WebsocketClientsViewer';
import LogsDetail from 'views/LogsDetail/LogsDetail';
import AnalysisDetail from 'views/Analysis/AnalysisDetail';
import Anomalies from "views/Anomalies/Anomalies"
import AnomaliesEdit from "views/Anomalies/AnomaliesEdit";

let routes = [
  {
    key: 'dashboard',
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'now-ui-icons design_app',
    component: Dashboard,
    layout: '/admin',
    invisible: false,
  },
  {
    collapse: true,
    path: '/forms',
    name: 'Forms',
    state: 'openForms',
    icon: 'now-ui-icons design_bullet-list-67',
    invisible: true,
    views: [
      {
        path: '/regular-forms',
        name: 'Regular Forms',
        mini: 'RF',
        component: RegularForms,
        layout: '/admin',
      },
      {
        path: '/extended-forms',
        name: 'Extended Forms',
        mini: 'EF',
        component: ExtendedForms,
        layout: '/admin',
      },
      {
        path: '/validation-forms',
        name: 'Validation Forms',
        mini: 'VF',
        component: ValidationForms,
        layout: '/admin',
      },
      {
        path: '/wizard',
        name: 'Wizard',
        mini: 'W',
        component: Wizard,
        layout: '/admin',
      },
    ],
  },
  // {
  //   key: 'authorization',
  //   path: '/authorization',
  //   name: 'Authorization',
  //   icon: 'now-ui-icons ui-1_lock-circle-open',
  //   component: Authorization,
  //   layout: '/admin',
  //   invisible: true,
  // },
  {
    key: 'roles-system',
    path: '/roles-system',
    name: 'Roles System',
    icon: 'now-ui-icons ui-1_lock-circle-open',
    component: RolesSystem,
    layout: '/admin',
    invisible: false,
  },
  {
    collapse: true,
    path: '/pages',
    name: 'Pages',
    state: 'openPages',
    icon: 'now-ui-icons design_image',
    invisible: true,
    views: [
      {
        path: '/register-page',
        name: 'Register Page',
        short: 'Register',
        mini: 'RP',
        component: RegisterPage,
        layout: '/auth',
      },
      {
        path: '/login-page',
        name: 'Login Page',
        short: 'Login',
        mini: 'LP',
        component: LoginPage,
        layout: '/auth',
      },
      {
        path: '/lock-screen-page',
        name: 'Lock Screen Page',
        short: 'Lock',
        mini: 'LSP',
        component: LockScreenPage,
        layout: '/auth',
        invisible: true,
      },
    ],
  },
  {
    path: '/charts',
    name: 'Charts',
    icon: 'now-ui-icons business_chart-pie-36',
    invisible: false,
    component: Charts,
    layout: '/admin',
  },
  
  //hacer vista
  {
    path: '/practice-setting',
    name: 'Practice Setting',
    icon: 'icon-practice-settings_sidebar',
    invisible: true,
    component: Dashboard,
    layout: '/admin',
  }, 


  {
    path: '/user-manual/new/',
    name: 'User Manual Create',
    icon: 'icon-practice-settings_sidebar',
    invisible: true,
    component: UserManualForm,
    layout: '/admin',
  },
  {
    path: '/user-manual/edit',
    name: 'User Manual Edit',
    icon: 'icon-practice-settings_sidebar',
    invisible: true,
    component: UserManualForm,
    layout: '/admin',
  },
  {
    path: '/user-manual',
    name: 'User Manual',
    icon: 'icon-folder',
    invisible: false,
    component: UserManual,
    layout: '/admin',
  },
  {
    path: '/practices-list',
    name: 'Practices',
    icon: 'icon-hospital',
    invisible: false,
    component: Practices,
    layout: '/admin',
  },
  {
    path: '/list-users/edit',
    name: 'User Edit',
    icon: 'icon-practice-settings_sidebar',
    invisible: true,
    component: DoctorUsersEdit,
    layout: '/admin',
  },
  {
    path: '/list-users',
    name: 'Users',
    icon: 'icon-research',
    invisible: false,
    component: DoctorUsers,
    layout: '/admin',
  },
  {
    path: '/list-patients',
    name: 'Patients',
    icon: 'icon-research',
    invisible: false,
    component: PatientUsers,
    layout: '/admin',
  },
  
  {
    path: '/wsList',
    name: 'Connected clients',
    icon: 'icon-list',
    invisible: false,
    component: WebsocketClientsViewer,
    layout: '/admin',
  },
  {
    path: "/logs",
    name: "Logs",
    icon: "now-ui-icons travel_info",
    invisible: false,
    component: LogsDetail,
    layout: "/admin",
  },
  {
    path: "/analysis",
    name: "Analysis",
    icon: "now-ui-icons media-2_sound-wave",
    invisible: false,
    component: AnalysisDetail,
    layout: "/admin",
  },
  {
    path: "/anomalies/edit",
    name: "Anomalies Edition",
    icon: "now-ui-icons media-2_sound-wave",
    invisible: true,
    component: AnomaliesEdit,
    layout: "/admin",
  },
  {
    path: "/anomalies",
    name: "Anomalies",
    icon: "now-ui-icons media-2_sound-wave",
    invisible: false,
    component: Anomalies,
    layout: "/admin",
  },
];

export default routes;
