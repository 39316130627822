import { takeEvery, put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import helpRoutes from 'plugins/TS-lib-utils-public/data/helpInfoWebDoctors.json';

//Redux Actions
import CONSTANTS from "../CONSTANTS";
import {
    actionSetTranslations,
    actionGetAllTranslations,
    actionSetKeyData
} from "../actions/manualActions";

//Services
import TranslationService from "../../services/TranslationService.js";
TranslationService.setBaseUrl("/translations");

// Functionatilies

function* getAllTranslations({ page, limit, search }){
    try {
        const result = yield call(TranslationService.getAll,page,limit,search);
        if (result.status >= 400 && result.status !== 404) {
            throw new Error("An error ocurred while fetching the list.");
        }

        if (result.status !== 404){
            yield put(actionSetTranslations(result.data.data));
        }else{
            yield put(actionSetTranslations([]));
        }
    } catch (error) {
        console.log(error);
        toast.error(error.message);
    }
};

function* deleteKey({ system, key }){
    try {
        const result = yield call(TranslationService.deleteKey,system,key);
        if (result.status >= 400) {
            throw new Error("An error ocurred while deleting the translation.");
        }
        const name = helpRoutes.routes.find(h => Number(h.key) === Number(key));
        toast.success(`Translation "${name ? name._id : key}" deleted succesfully!`);
        yield put(actionGetAllTranslations());
    } catch (error) {
        console.log(error);
        toast.error(error.message);
    }
};

function* editKey({ system, id, data }){
    try {
        //Get the list of available languages for the translation
        const languageList = yield call(TranslationService.getLanguages, system, id);
        if (languageList.status >= 400 && languageList.status !== 404) {
            throw new Error("An error ocurred while editing the translation.");
        }

        if(languageList.status !== 404){

            //Create a list of languages which needs to be created, updated, and deleted
            const updateList = data.text.filter(text => {
                return languageList.data.findIndex(el => {return el.language === text.language}) !== -1;
            });
            const createList = data.text.filter(text => {
                return languageList.data.findIndex(el => {return el.language === text.language}) === -1;
            });
            const deleteList = languageList.data.filter(text => {
                return data.text.findIndex(el => {return el.language === text.language}) === -1;
            });
            
            //Perform api calls for each list
            for (const text of updateList){
                const langStatus = yield call(TranslationService.updateLanguage, system, id, text.language, text);
                if (langStatus.status >= 400){
                    throw new Error("An error ocurred while editing the translation.");
                }
            }

            for (const text of createList){
                const langStatus = yield call(TranslationService.createLanguage, system, id, text);
                if (langStatus.status >= 400){
                    throw new Error("An error ocurred while editing the translation.");
                }
            }

            for (const text of deleteList){
                const langStatus = yield call(TranslationService.deleteLanguage, system, id, text.language);
                if (langStatus.status >= 400){
                    throw new Error("An error ocurred while editing the translation.");
                }
            }
        }else{
            //The translation has no languages, create
            for (const text of data.text){
                const langStatus = yield call(TranslationService.createLanguage, system, id, text);
                if (langStatus.status >= 400){
                    throw new Error("An error ocurred while editing the translation.");
                }
            }
        }

        //Edit the information of the translation
        const result = yield call(TranslationService.editKey,system,id,data);
        if (result.status >= 400) {
            throw new Error("An error ocurred while editing the translation.");
        }
        const name = helpRoutes.routes.find(h => Number(h.key) === Number(id));
        toast.success(`Translation "${name ? name._id : id}" edited succesfully!`);
        yield put(actionGetAllTranslations());
    } catch (error) {
        console.log(error);
        toast.error(error.message);
    }
};

function* createKey({ data }){
    try {
        const result = yield call(TranslationService.createKey, data);
        if (result.status >= 400) {
            throw new Error("An error ocurred while creating the translation.");
        }

        for (const text of data.text){
            const languageStatus = yield call(TranslationService.createLanguage, data.system, data.key, text);
            if (languageStatus.status >= 400){
                throw new Error("An error ocurred while creating the language.");
            }
        }
        const name = helpRoutes.routes.find(h => Number(h.key) === Number(data.key));
        toast.success(`Translation "${name ? name._id : data.key}" created succesfully!`);
        yield put(actionGetAllTranslations());
    } catch (error) {
        console.log(error);
        toast.error(error.message);
    }
};

function* getKeyData({ system, key }){
    try {
        const result = yield call(TranslationService.getKeyData, system, key);
        if (result.status >= 400) {
            throw new Error("The data of the translation could not be retrieved.");
        }
        yield put(actionSetKeyData(result.data));
    } catch (error) {
        console.log(error);
        toast.error(error.message);
    }
};

export function* manualSaga() {
  yield takeEvery(CONSTANTS.MANUAL_GET_ALL, getAllTranslations);
  yield takeEvery(CONSTANTS.MANUAL_DELETE_KEY, deleteKey);
  yield takeEvery(CONSTANTS.MANUAL_EDIT_KEY, editKey);
  yield takeEvery(CONSTANTS.MANUAL_GET_KEY, getKeyData);
  yield takeEvery(CONSTANTS.MANUAL_CREATE_KEY, createKey);
}
