import CONSTANTS from "../CONSTANTS";

const initialState = {
  pagination: {},
  list: [],
  data: {},
};

const manualReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.MANUAL_SET_ALL:
          return { ...state, pagination: action.data.pagination, list: action.data.keys };
    case CONSTANTS.MANUAL_SET_KEY:
          return { ...state, data: action.data };
    default:
        return state;
  }
};

export default manualReducer;