import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { translate } from "react-translate";
import ListLogs from 'components/Logs/ListLogs';

const LogsDetail = (props) => {
  const {t} = props;

  return (
    <div className='content user-manual'>
      <Card>
        <CardHeader>
          <h5 className="title">{t("title-header")}</h5>
        </CardHeader>
        <CardBody>
          <ListLogs />
        </CardBody>
      </Card>
    </div>
  );
};

export default translate("logsDetail")(LogsDetail);
