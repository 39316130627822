import React, { useState } from "react";
//import { useSelector, useDispatch } from "react-redux";
//import { actionSetLang } from "store/actions/usersActions";
//import languages from "configs/lang";
import Icon from "components/Icons/Icon";

import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";

function HelpMenu() {
    const [dropdownOpenHelp, setdropdownOpenHelp] = useState(false);

    const dropdownToggleHelp = () => {
        setdropdownOpenHelp(!dropdownOpenHelp);
    }

    return (
        <Dropdown nav isOpen={dropdownOpenHelp} toggle={dropdownToggleHelp}>
            <DropdownToggle caret nav>
            <Icon file="hospital.svg" width="21px" title="Practices" />
            <p className="ml-2">
                <span className="d-lg-none d-md-block">Practices</span>
            </p>
            </DropdownToggle>
            <DropdownMenu right>
            <DropdownItem tag="a" href="#">
                Action
            </DropdownItem>
            <DropdownItem tag="a" href="#">
                Other Action
            </DropdownItem>
            <DropdownItem tag="a" href="#">
                Another Action
            </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}

export default HelpMenu;
