import CONSTANTS from '../CONSTANTS';

const initialState = {
  doctors: [],
  editData: {},
};

const usersDoctorReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.USERS_DOCTOR_SET_LIST:
        return { ...state, doctors: action.doctors };   
    case CONSTANTS.USERS_DOCTOR_SET_EDIT_DATA:
        return {...state, editData: action.data};
    default:
      return state;
  }
};

export default usersDoctorReducer;
