import React from 'react';
import { Card, CardBody, CardHeader  } from 'reactstrap';
import { translate } from "react-translate";
import UserManualList from 'components/UserManual/UserManualList';

const UserManual = (props) => {
  const {t} = props;

  return (
    <div className='content user-manual'>
      <Card>
          <CardHeader>
            <h5 className="title">{t("title-header")}</h5>
          </CardHeader>
        <CardBody>
          <UserManualList />
        </CardBody>
      </Card>
    </div>
  );
};

export default translate('userManual')(UserManual);
