/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { translate } from "react-translate";
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Form,
  Container,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";

// core components
import { actionLogin } from "store/actions/usersActions";

const LoginPage = (props) => {
  const { t } = props;

  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer);

  const [usernameFocus, setUsernameFocus] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [loginForm, setLoginForm] = useState({
    username: "",
    password: "",
    usernameState: "",
    passwordState: "",
  });

  //Validations
  const loginEmail = (e) => {
    let login = {
      username: null,
      usernameState: null,
    };
    login.username = e.target.value;
    if (e.target.value.length > 4) {
      login.usernameState = "has-success";
    } else {
      login.usernameState = "has-danger";
    }
    setLoginForm({ ...loginForm, ...login });
  };

  const loginPassword = (e) => {
    let login = {
      password: null,
      passwordState: null,
    };
    login.password = e.target.value;
    if (e.target.value.length > 2) {
      login.passwordState = "has-success";
    } else {
      login.passwordState = "has-danger";
    }
    setLoginForm({ ...loginForm, ...login });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      loginForm.usernameState === "has-success" &&
      loginForm.passwordState === "has-success"
    ) {
      await dispatch(
        actionLogin({
          username: loginForm.username,
          password: loginForm.password,
        })
      );
    }
  };

  const isAuth = () => {
    if (user.isAuth && localStorage.getItem('tsm-token')) {
      props.history.push("/admin/dashboard");
    }
  };

  useEffect(() => {
    document.body.classList.add("login-page");
    isAuth();
    return () => {
      document.body.classList.remove("login-page");
    };
    // eslint-disable-next-line
  }, [user.isAuth]);

  return (
    <Fragment>
      <div className="content">
        <img
          src="/General/login/background.png"
          width="100%"
          alt=""
          className="mt-5"
        />
        <div className="login-page">
          <Container>
            <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
              <Form onSubmit={handleSubmit}>
                <Card className="card-login card-plain">
                  <CardHeader className="login-title">
                    <img src={"/General/login/logo.svg"} width="100%" alt="" />
                  </CardHeader>
                  <CardBody>
                    <InputGroup
                      className={
                        (usernameFocus ? " input-group-focus " : "") +
                        loginForm.usernameState
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons users_circle-08" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        placeholder={t("username") + "..."}
                        onFocus={(e) => setUsernameFocus(true)}
                        onBlur={(e) => setUsernameFocus(false)}
                        onChange={loginEmail}
                      />
                    </InputGroup>
                    <InputGroup
                      className={
                        (passwordFocus ? " input-group-focus " : "") +
                        loginForm.passwordState
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons text_caps-small" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="password"
                        placeholder={t("password") + "..."}
                        onFocus={(e) => setPasswordFocus(true)}
                        onBlur={(e) => setPasswordFocus(false)}
                        onChange={loginPassword}
                      />
                    </InputGroup>
                  </CardBody>
                  <CardFooter>
                    <Button
                      block
                      type="submit"
                      color="info"
                      size="lg"
                      className="btn-block"
                    >
                      {t("login")}
                    </Button>
                    <div className="pull-left">
                      <h6>
                        <Link
                          to="/auth/register-page"
                          className="link footer-link"
                        >
                          {t("create-account")}
                        </Link>
                      </h6>
                    </div>
                    <div className="pull-right">
                      <h6>
                        <a href="#pablo" className="link footer-link">
                          {t("need-help")}?
                        </a>
                      </h6>
                    </div>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
      </div>
      {/* <div
        className="full-page-background"
        style={{ backgroundImage: "url(" + bgImage + ")" }}
      /> */}
    </Fragment>
  );
};

export default translate("login-page")(LoginPage);
