import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { translate } from "react-translate";
import ListDoctorUsers from 'components/ListDoctorUsers/ListDoctorUsers';
//import ListPractices from 'components/ListPractices/testHooks';

const DoctorUsers = (props) => {
  const {t} = props;
  
  return (
    <div className='content user-manual'>
      <Card>
        <CardHeader>
          <h5 className="title">{t("title-header")}</h5>
        </CardHeader>
        <CardBody>
          <ListDoctorUsers />
        </CardBody>
      </Card>
    </div>
  );
};

export default translate("userList")(DoctorUsers);
