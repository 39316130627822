import React from 'react';
import PropTypes from "prop-types";
import { Button, Table } from 'reactstrap';
import { translate } from "react-translate";
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';
import { useDispatch } from "react-redux";
import { actionResolveModerationState } from 'store/actions/practicesActions';

const TablePendingPractices = (props) => {

  const { practices, loading } = props;
  const { t } = props;
  const dispatch = useDispatch();

  const resolveModeration = (practiceId, status) => {
    dispatch(actionResolveModerationState(practiceId, status));
  }

  return (
    <>
      {loading ?
        (<LoadingLocal />)
        :
        (
        !practices || !practices.length ? (
          <p>
            No practices pending for moderation
          </p>
        ) : (
          <div className="table-data">
          <Table>
            <thead>
              <tr>
                <th> {t("name-practice")} </th>
                <th> {t("street-practice")} </th>
                <th> {t("tel-practice")} </th>
                <th> {t("postal-practice")} </th>
                <th> {t("contact-practice")} </th>
                <th> {t("city-practice")} </th>
                <th> {t("mail-practice")} </th>
                <th> Actions </th>
              </tr>
            </thead>

            {practices.length > 0 && (practices.map((p, index) => {
              return (
                <tbody key={index}>
                  <tr>
                    <th>{p.name}</th>
                    <th>{p.street}</th>
                    <th>{p.telephone}</th>
                    <th>{p.postal_code}</th>
                    <th>{p.contact_person}</th>
                    <th>{p.country}</th>
                    <th>{p.email_accounting}</th>
                    <th className="d-flex flex-row justify-content-around">
                      <Button
                        size="sm" color="success"
                        onClick={(e) => {
                          resolveModeration(p._id, true);
                        }}
                      >
                        Approve
                      </Button>

                      <Button
                        size="sm" color="danger"
                        onClick={(e) => {
                          resolveModeration(p._id, false);
                        }}
                      >
                        Deny
                      </Button>
                    </th>
                  </tr>
                </tbody>

              )
            })

            )}

          </Table>
        </div>
        ))}
    </>
  );
}

TablePendingPractices.defaultProps = {
  practices: [],
  loading: true
};

TablePendingPractices.propTypes = {
  practices: PropTypes.array,
  loading: PropTypes.bool
};

export default translate("practice-table")(TablePendingPractices);