import CONSTANTS from "../CONSTANTS";
let langs = ["de", "ru", "en", "es"];
let langBrowser = (navigator.language || navigator.userLanguage).substr(0, 2);
let langDefault = langs.includes(langBrowser) ? langBrowser : langs[0];

const initState = {
  isAuth: false,
  key: null,
  uri: null,
  auth_2fa: "",
  loading: false,
  language: langDefault,
  dateFormat: "YYYY-MM-DD",
  practices: []
};
const userReducer = (state = initState, action) => {
  switch (action.type) {
    case CONSTANTS.USERS_SET:
      if (typeof action.user !== "undefined") {
        return { ...action.user, isAuth: true };
      }
      return initState;
    case CONSTANTS.USERS_LOGOUT:
      return initState;
    case CONSTANTS.USERS_SET_LANG:
      return { ...state, language: action.language };
    case CONSTANTS.USERS_SET_DATE_FORMAT:
      return { ...state, dateFormat: action.dateFormat };
    case CONSTANTS.AUTH2FA_SET:
      return {
        ...state,
        key: action.qr.key,
        uri: action.qr.uri,
      };
    case CONSTANTS.USERS_LOADING:
      return { ...state, loading: action.loading };  
    default:
      return state;
  }
};

export default userReducer;
