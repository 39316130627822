export default {
  _id: "000000000000000000000001",
  first_name: "Admin",
  last_name: "Test",
  language: "en",
  dateFormat: "YYYY-MM-DD",
  username: "admin",
  session_expire: 86400,
  token:
    "doc:19ebfde5-3047-4ed2-b625-5d36aab1e10c:36d05702-dc20-44b7-b9b5-1b9a529017af",
  status: "success",
  auth_2fa: false,
  message: "Authorized user access admin",
};
