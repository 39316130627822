import AxiosService from "plugins/TS-lib-utils-public/services/AxiosService"; 

class UsersDoctorService extends AxiosService {
    constructor() {
        super("users");
    }
  
    getUserDoctor = async (page, limit, search = null) => {
        let query;

        if (search) {
            query = `/list?page=${page}&limit=${limit}&search=${search}`;
        } else {
            query = `/list?page=${page}&limit=${limit}`;
        }
        const response = await this.get(
            query,
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            }
        );
        return response;
    };
    getAllSystemUsers = async (days) => {
        let query;
    
        query = `/statistics?days=${days}`;
    
        const response = await this.get(
          query,
          {
            headers: {
              Authorization: `Bearer ${this.getToken()}`,
            },
          }
        );
        return response;
      };

    updateUser = async (data) => {
        return await this.put(`/management-edit`, data, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
            },
        });
    }

}

export default new UsersDoctorService();
