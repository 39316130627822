import CONSTANTS from "../CONSTANTS";

const initialState = {
  modules: [],
  module: {},
};

const modulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.MODULES_SET:
      return { ...state, modules: action.modules };
    case CONSTANTS.MODULES_SET_ONE:
      return { ...state, module: action.module };
    default:
      return state;
  }
};

export default modulesReducer;
