import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import PropTypes from "prop-types";
import { Table, Button } from 'reactstrap';
import { translate } from "react-translate";
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';

import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';

const TableAnalysis = (props) => {
    const { data, loading } = props;
    const { t } = props;
    const dispatch = useDispatch();
    const history = useHistory();

    const replaceMessage = (message, keys) => {
        try {
            return t(message, keys).replace('analysisDetail.','');
        } catch(e) {
            console.log(e);
            return message;
        }
    }

    return (
        <>
          {loading ? (
            <LoadingLocal />
          ) : (
            <div className="table-responsive">
              <Table id="logs" className="table-responsive">
                <thead>
                  <tr>
                    <th>{t("control")}</th>
                    <th>{t("order")}</th>
                    <th>{t("patient")}</th>
                    <th>{t("doctor")}</th>
                    <th>{t("HL7")} {t("pending")}</th>
                    <th>{t("PDF")} {t("pending")}</th>
                    <th>{t("error")}</th>
                    <th>{t("created")}</th>
                  </tr>
                </thead>
                {data.length > 0 && (
                  <tbody>
                    {data.map((p, index) => {
                      let token = localStorage.getItem('tsm-token');
                      let clase = p.inError ? "danger" : "table-info";
                      let error = p.inError ? t("yes") : t("no");
                      let hl7_pending = p.hasUndeliveredHl7 ? t("yes") : t("no");
                      let pdf_pending = p.hasUndeliveredPDF ? t("yes") : t("no");
                      let created = moment(p.created).format("YYYY-MM-DD HH:mm:ss SSS");
                      return (
                        <React.Fragment key={index}>
                          <tr className={clase}>
                            <td>{p.control}</td>
                            <td>{p.order}</td>
                            <td>{p.patientId}</td>
                            <td>
                              <p>{p.doctorId}</p>
                              {p.originalDoctorId !== null && (
                                <>
                                <div className="rbc-event event-default rbc-event-allday">
                                  <div className="rbc-event-content">{p.originalDoctorId}</div>
                                </div>
                                </>
                              )}
                            </td>
                            <td>{hl7_pending}</td>
                            <td>{pdf_pending}</td>
                            <td>{error}</td>
                            <td>{created}</td>
                          </tr>
                          <tr className="small">
                            <td colSpan="8">
                              <table>
                                <thead>
                                  <tr>
                                    <td>
                                      <table className="table-responsive">
                                          <thead>
                                            <tr>
                                              <th>{t("HL7")}</th>
                                              <th>{t("status")}</th>
                                              <th>{t("delivered")}</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {p.hl7s.length > 0 ? (
                                              p.hl7s.map((hl7, hl7Index) => (
                                                <tr key={hl7Index}>
                                                  <td>
                                                    <a
                                                        href={`${process.env.REACT_APP_HOST}/hl7/report/${p._id}/${hl7.name}?token=${token}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                    {hl7.name}
                                                    </a>
                                                  </td>
                                                  <td>{hl7.status}</td>
                                                  <td>{hl7.isDelivered ? t("yes") : t("no")}</td>
                                                </tr>
                                              ))
                                            ) : (
                                              <tr>
                                                <td colSpan="3">{t("no")}</td>
                                              </tr>
                                            )}
                                          </tbody>
                                      </table>
                                    </td>
                                    <td>
                                      <table className="table-responsive">
                                        <thead>
                                          <tr>
                                            <th>{t("PDF")}</th>
                                            <th>{t("delivered")}</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {p.pdfs.length > 0 ? (
                                            p.pdfs.map((pdf, pdfIndex) => (
                                              <tr key={pdfIndex}>
                                                <td>
                                                  <a
                                                      href={`${process.env.REACT_APP_HOST}/hl7/getpdf/${p._id}/${pdf.name}?token=${token}`}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                  {pdf.name}
                                                  </a>
                                                </td>
                                                <td>{pdf.isDelivered ? t("yes") : t("no")}</td>
                                              </tr>
                                            ))
                                          ) : (
                                            <tr>
                                              <td colSpan="2">{t("no")}</td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </thead>
                              </table>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                )}
              </Table>
            </div>
          )}
        </>
      );
};
TableAnalysis.defaultProps = {
    data: [],
    loading: true
};

TableAnalysis.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool
};
export default translate("analysisDetail")(TableAnalysis);