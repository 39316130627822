import React, { useState, useEffect, useMemo } from 'react';
import { Button, Table } from 'reactstrap';
import { useSortBy, useTable, usePagination } from 'react-table';
import TagsInput from 'react-tagsinput';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  actionGetAllTranslations,
  actionGetKeyData,
  actionDeleteKey,
  actionSetKeyData
} from 'store/actions/manualActions';
import helpRoutes from 'plugins/TS-lib-utils-public/data/helpInfoWebDoctors.json';
import Paginator from 'components/Common/Paginator';

//Sweet alert
import SweetAlert from "react-bootstrap-sweetalert";
import { actionSweetAlert } from "store/actions/commonActions";

import { useDispatch } from "react-redux";

const UserManualList = () => {

  const dispatch = useDispatch();

  const dataInfo = useSelector(state => state.manualReducer.list);
  const paginationInfo = useSelector(state => state.manualReducer.pagination);

  //page data
  const [pageNum, setPageNum] = useState(0);
  const [pageLimit, setPageLimit] = useState(5);
  const [pageTotal, setPageTotal] = useState(1);
  const [pageFilter, setPageFilter] = useState(null);

  //Pagination effect
  useEffect(() => {
    dispatch(actionGetAllTranslations(pageNum, pageLimit, pageFilter));
    // eslint-disable-next-line
  }, [pageNum, pageLimit, pageTotal, pageFilter]);

  useEffect(() => {
    if (paginationInfo) {
      setPageTotal(paginationInfo._total_pages);
    }
    
  }, [paginationInfo]);

  const data = useMemo(
    () => dataInfo && dataInfo.length ? dataInfo.map(key => {
      let available = [];
      let keyValue = helpRoutes.routes.find((h) => Number(h.key) === Number(key.key));

      //Replace the unique id with the name of the key
      if (!keyValue) {
        keyValue = key.key;
      } else {
        keyValue = keyValue._id;
      }
      
      key.text.map((text) => {
        available.push(text.language);
        return true;
      });

      return {
        ...key,
        key: keyValue,
        tags: ( key.tags ?
          <TagsInput
            inputProps={{
              name: 'tags',
              placeholder: '',
            }}
            value={key.tags}
            tagProps={{ className: 'react-tagsinput-tag' }}
            onChange={() => { }}
          />
          : <div></div>),
        available: (
          <TagsInput
            inputProps={{
              name: 'available',
              placeholder: '',
            }}
            value={available}
            tagProps={{ className: 'react-tagsinput-tag' }}
            onChange={() => { }}
          />
        ),
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              onClick={(e) => editHelp(e)}
              className="btn-icon btn-round"
              color="warning"
              size="md"
              data-id={`${key.key}|${key.system}`}>
              <i className="fa fa-edit" data-id={`${key.key}|${key.system}`} />
            </Button>
            {/* use this button to remove the data row */}
            <Button
              onClick={(e) => deleteHelp(e)}
              className="btn-icon btn-round"
              color="danger"
              size="md"
              data-id={`${key.key}|${key.system}`}>
              <i className="fa fa-times" data-id={`${key.key}|${key.system}`}></i>
            </Button>
          </div>
        ),
      };
    }) : [],
    //eslint-disable-next-line
    [dataInfo]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Key',
        accessor: 'key',
      },
      {
        Header: 'Title',
        accessor: 'title',
        disableSortBy: true,
      },
      {
        Header: 'Tags',
        accessor: 'tags',
        disableSortBy: true,
      },
      {
        Header: 'Available in',
        accessor: 'available',
        disableSortBy: true,
      },
      {
        Header: 'System',
        accessor: 'system',
        disableSortBy: true,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableSortBy: true,
      },
    ], 
    //eslint-disable-next-line
    [dataInfo]
  );

  const {
    getTableProps,
   getTableBodyProps,
   headerGroups,
   rows,
   prepareRow,
  } = useTable({
    columns, 
    data,
    initialState: { pageIndex: 0 },
    manualPagination: true,
    pageCount: pageTotal
  }, useSortBy, usePagination);

  const history = useHistory();

  //method called by search
  function changeData(page) {  
    setPageNum(page);
  }

  const createHelp = (e) => {
    dispatch(actionSetKeyData({}));
    history.push('/admin/user-manual/new/');
  };

  const editHelp = (e) => {
    const value = e.target.dataset.id;
    const id = value.split('|')[0];
    const system = value.split('|')[1];
    dispatch(actionGetKeyData(system, id));
    history.push(`/admin/user-manual/edit/`);
  };

  const deleteHelp = (e) => {
    const value = e.target.dataset.id;
    const id = value.split('|')[0];
    const system = value.split('|')[1];
    dispatch(
      actionSweetAlert(
        <SweetAlert
          warning
          title={`Are you sure you want to delete "${id}"?`}
          onConfirm={() => {
            dispatch(actionDeleteKey(system, id));

            /* Go back to the previous page if the current page has no data after deletion*/
            if (dataInfo.length === 1 && pageNum > 0) {
              setPageNum(pageNum - 1);
            }

            dispatch(actionSweetAlert(null));
          }}
          onCancel={() => dispatch(actionSweetAlert(null))}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="info"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
        >
          All the data related to it will be lost!
        </SweetAlert>
      )
    );
  };

  return (
    <>
      <Button
        size="sm"
        color="info"
        onClick={(e) => { createHelp(e) }}
      >
        Add new translation
      </Button>

      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                    className={`session-table-header`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>

      <Paginator totalPages={pageTotal} currentPage={pageNum} pageSize={pageLimit} filter={pageFilter} callback={changeData}/>
    </>
  );
};

export default UserManualList;
